import Bugsnag from "@bugsnag/js"
import { production } from "../config"
import { history } from "../history"
// import { loginUserAction, logoutUserAction } from "../network/redux/actions/auth/loginActions";
// import { store } from "../network/redux/storeConfig/store";

export const userKey = () => {
  let key = "user-tr-teachers"
  if (!production) {
    key = `${key}-dev`
  }
  return key
}

export const login = (auth, fromSignup = false, pathname = "/") => {
  Bugsnag.setUser(auth?.student?.id, auth?.student?.email, auth?.student?.name)
  // store.dispatch(loginUserAction(auth));
  localStorage.setItem(userKey(), JSON.stringify(auth))
  history.push(pathname, { demoStudentModal: fromSignup })
  // history.push({
  //   pathname,
  //   hash: fromSignup ? "success" : undefined,
  //   state: { demoStudentModal: fromSignup },
  // });
  window.location.reload()
  // window.location.href = pathname;// Refresh page on enter
}

export const logout = () => {
  // store.dispatch(logoutUserAction());
  localStorage.removeItem(userKey())
  Bugsnag.setUser(null)
  history.push("/login")
}

export const getUser = () => localStorage.getItem(userKey())

export const getToken = () => {
  let auth = getUser()
  return auth ? JSON.parse(auth).token : undefined
}

export const updateGender = () => {
  let user = JSON.parse(getUser())
  user.user.gender =
    user?.user?.gender === true ||
    user?.user?.gender === "true" ||
    user?.user?.gender === 1
      ? "M"
      : "F"
  localStorage.setItem(userKey(), JSON.stringify(user))
  return user
}

export const setActiveClass = (classId) => {
  let user = JSON.parse(getUser())
  user.user.class = user.user.classes.find((c) => c.id === classId)
  localStorage.setItem(userKey(), JSON.stringify(user))
  return user
}

export const updateLocalClasses = (classes, callback = () => {}) => {
  let user = JSON.parse(getUser())
  user.user.classes = classes
  localStorage.setItem(userKey(), JSON.stringify(user))
  callback()
  return user
}

export const updateClass = (newClass) => {
  let user = JSON.parse(getUser())
  user.user.class = newClass
  localStorage.setItem(userKey(), JSON.stringify(user))
  return user
}

export const getClassId = () => {
  let user = JSON.parse(getUser())
  const classId = user?.user?.class?.id
  if (classId) {
    return classId
  } else {
    return undefined
  }
}

export const isLessThan30DaysOld = (dateString) => {
  if (!dateString) return false

  let diff = Math.round(
    (new Date() - new Date(dateString)) / (1000 * 60 * 60 * 24)
  )
  
  return diff <= 30
}
