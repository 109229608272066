import React from "react"
import Colors from "../helpers/Colors"

export default class ColoredBar extends React.Component {
  render() {
    let styles = {
      height: 8,
      backgroundColor: Colors.SEPARATOR,
      borderRadius: 100,
      overflow: "hidden",
    }
    if (this.props.style) styles = { ...styles, ...this.props.style }
    return (
      <div
        className={`w-100 d-flex flex-row ${this.props.className}`}
        style={styles}
      >
        {this.props.bars.map((barProps, index) => (
          <div
            key={index}
            style={{
              backgroundColor: barProps.color,
              width: `${barProps.size * 100}%`,
              height: styles.height,
              overflow: "hidden",
            }}
          />
        ))}
      </div>
    )
  }
}
