import React from "react"
import Colors from "../helpers/Colors"
import { Col, Modal, ModalBody } from "reactstrap"
import { Lottie } from "."
import { logout } from "../helpers/AuthHelper"

export default class ToriModal extends React.Component {
  render() {
    return (
      <Modal
        isOpen={this.props.visible}
        toggle={this.props.closeModal ? this.props.closeModal : () => {}}
        className="modal-dialog-centered"
        style={{ marginTop: 110, marginBottom: 0 }}
      >
        <Lottie lottie={`Modal${this.props.talking ? "Talking" : ""}`} />
        <ModalBody
          style={{
            textAlign: "center",
            backgroundColor: this.props.color ? this.props.color : "inherit",
            borderRadius: "0.6rem",
          }}
        >
          <Col className="d-flex flex-column align-items-center px-3 pt-3">
            {this.props.title && (
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                  color: Colors.TEXT,
                  marginBottom: 10,
                }}
              >
                {this.props.title}
              </span>
            )}
            {this.props.children}
            {this.props.closeModal ? null : (
              <span
                className="mt-1 cursor-pointer"
                style={{ fontSize: 10, color: Colors.RED }}
                onClick={()=>{
                  // eslint-disable-next-line
                  if (confirm("Are you sure you want to sign out?")) {
                    logout()
                  }
                }}
              >
                Or sign out
              </span>
            )}
          </Col>
        </ModalBody>
      </Modal>
    )
  }
}
