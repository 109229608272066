import React from "react"
import { ChevronDown } from "react-feather"
import { Input } from "reactstrap"

export default class Dropdown extends React.Component {
  onChange = (e) => {
    if (this.props.onChange) {
      this.props.onChange(e)
    }
  }
  render() {
    return (
      <div className={this.props.className} style={{ position: "relative" }}>
        <Input
          style={{
            ...{ backgroundColor: "transparent", cursor: "pointer" },
            ...this.props.style,
          }}
          name={this.props.name}
          type="select"
          value={this.props.value}
          onChange={this.onChange}
        >
          {this.props.children}
        </Input>
        {this.props.customArrow ? (
          this.props.customArrow
        ) : (
          <ChevronDown
            style={{ position: "absolute", right: 5, top: "20%", zIndex: -1 }}
          />
        )}
      </div>
    )
  }
}
