import { logError } from "./ErrorHelper"
import * as Langs from "./langs"

const langKey = () => {
  return "en"
}

const parseParams = (string = "", params = {}) => {
  let localString = string || ""
  Object.keys(params).forEach((paramName) => {
    localString = localString.replaceAll(
      "${" + paramName + "}",
      params[paramName] || ""
    )
  })
  return localString
}

export const lang = (key, params = undefined) => {
  const response = Langs[langKey()][key]
  if (response === undefined) {
    let msg = `MISSING '${key}' TEXT`
    logError(msg)
    return msg
  } else if (params !== undefined) {
    return parseParams(response, params)
  } else {
    return response
  }
}

export const exists = (key) => {
  const response = Langs[langKey()][key]
  return response !== undefined
}

export const propertyOnName = (name) => {
  return name ? `${name}'${name[name.length - 1] !== "s" ? "s" : ""}` : ""
}
