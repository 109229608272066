import React from 'react';

const disabledColor = '187, 187, 187';

const color = [
  '53, 177, 255',
  '109, 120, 255',
  '255, 161, 52',
  '64, 215, 143',
  '255, 60, 151',
];

export default class RandomAvatar extends React.Component {
  constructor(props) {
    super(props);
    this.size = this.props.size;
    this.color = color[Math.floor(Math.random() * color.length)];//TODO: Replace with this.getRandomIndex(this.props.name)
  }

  getRandomIndex(string) {
    let sum = string.split('').map(x => x.charCodeAt(0)).reduce((a, b) => a + b);
    // console.log("ASCII sum", string, sum, Math.floor(sum / color.length));//TODO: Complete algorithm
    return sum;
  }

  getInitials() {
    let words = [];
    this.props.name.split(" ").forEach(word => {
      words.push(word.charAt(0).toUpperCase() + word.slice(1));
    });
    return (words.length > 1 ? `${words[0].charAt(0)}${words[words.length - 1].charAt(0)}` : `${words[0].charAt(0)}${words[0].charAt(1)}`);
  }

  render() {
    return (
      <div style={{...{ borderRadius: 100, width: this.size * 2.7, height: this.size * 2.7, backgroundColor: `white` },...this.props.style}}>
        <div style={{ borderRadius: 100, padding: 5, width: this.size * 2.7, height: this.size * 2.7, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: `rgba(${this.props.disabled ? disabledColor : this.color}, 0.3)` }}>
          <span style={{ fontWeight: 'bold', fontSize: `${this.size}px`, color: `rgb(${this.props.disabled ? disabledColor : this.color})` }}>{this.getInitials()}</span>
        </div>
      </div>
    )
  }
}