import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

class PathLocationManager {
  pastLocations = [];
  key = "appLocationHistory";

  constructor() {
    const jsonFromSessionStorage = sessionStorage.getItem(this.key);
    this.pastLocations = jsonFromSessionStorage ? (JSON.parse(jsonFromSessionStorage)) : [];
  }

  push(location) {
    this.pastLocations.push(location);
    this.dumpToSessionStorage();
  }

  pop() {
    this.pastLocations.pop();
    this.dumpToSessionStorage();
  }

  length() {
    return this.pastLocations.length;
  }

  setLocation(index, location) {
    this.pastLocations[index] = location;
    this.dumpToSessionStorage();
  }

  getLocation(index) {
    return this.pastLocations[index];
  }

  actualPath() {
    return pastLocations.getLocation(pastLocations.length() - 1)
  }

  setLocations(locations) {
    this.pastLocations = locations;
    this.dumpToSessionStorage();
  }

  dumpToSessionStorage() {
    sessionStorage.setItem(this.key, JSON.stringify(this.pastLocations));
  }
}

const pastLocations = new PathLocationManager();

function updatePastLocations(location, action) {
  switch (action) {
    case "PUSH":
      // first location when app loads and when pushing onto history
      pastLocations.push(location);
      break;
    case "REPLACE":
      // only when using history.replace
      pastLocations.setLocation(pastLocations.length() - 1, location);
      break;
    case "POP": {
      // happens when using the back button, or forward button
      pastLocations.pop();
      // location according to pastLocations
      const appLocation = pastLocations.getLocation(pastLocations.length() - 1);
      if (!(appLocation && appLocation.key === location.key)) {
        // If the current location doesn't match what the app thinks is the current location,
        // blow up the app history.
        pastLocations.setLocations([location]);
      }
      break;
    }
    default:
  }
}
history.listen(updatePastLocations);