import React from "react"
import { Modal as ModalContainer, ModalBody } from "reactstrap"

export default class Modal extends React.Component {
  render() {
    return (
      <ModalContainer
        isOpen={this.props.visible}
        toggle={this.props.closeModal ? this.props.closeModal : () => {}}
        className="modal-dialog-centered"
        style={{ maxWidth: 890 }}
      >
        <ModalBody
          style={{
            textAlign: "center",
            backgroundColor: this.props.color ? this.props.color : "inherit",
            borderRadius: "0.6rem",
          }}
        >
          {this.props.children}
        </ModalBody>
      </ModalContainer>
    )
  }
}
