export function levelMapper(data) {
  return {
    actual: data.user.badges[0].order,
    progress: data.next_badge.completed_exams,
    next: data.next_badge.required_to_upgrade,
  };
}

export function milestonesMapper(data) {
  const milestonesArray = [];
  data.user.milestones.forEach((milestone) => {
    if (milestone) {
      milestonesArray.push({
        title: milestone?.name,
        description: '',
        achieved: true,
      });
    }
  });
  return milestonesArray;
}

export function inviteUserMapper(user) {
  return {
    email: user?.email,
    role: {
      id: user?.role?._id,
      name: user?.role?.name
    },
    className: user?.class?.name
  };
}