import React from "react";
import { ChevronRight } from "react-feather";
import Colors from "../helpers/Colors";
import PlayAudio from "../helpers/PlayAudio";
import upperLeft from "../assets/img/tutorial/upperLeft.png"
import lowerLeft from "../assets/img/tutorial/lowerLeft.png"
import lowerLeftAlt from "../assets/img/tutorial/lowerLeftAlt.png"
import upperRight from "../assets/img/tutorial/upperRight.png"
import lowerRight from "../assets/img/tutorial/lowerRight.png"

export default class Tutorial extends React.Component {
  Images = () => { return { upperLeft, lowerLeft, lowerLeftAlt, upperRight, lowerRight } }
  render() {
    const overlay = { position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: '#fff7' };
    let container = {
      display: 'flex',
      zIndex: 10,
      borderRadius: 15,
      padding: 10,
      position: 'absolute',
      width: '50vw',
      height: 200,
      alignItems: 'flex-start',
      flexDirection: (this.props.arrow === 'upperLeft' || this.props.arrow === 'lowerLeft' || this.props.arrow === 'lowerLeftAlt') ? 'row' : 'row-reverse'
    };
    container[(this.props.arrow === 'upperLeft' || this.props.arrow === 'lowerLeft' || this.props.arrow === 'lowerLeftAlt') ? 'left' : 'right'] = (this.props.offset || 0);
    container[(this.props.arrow === 'upperLeft' || this.props.arrow === 'upperRight') ? 'bottom' : 'top'] = this.props.arrow === 'lowerLeftAlt' ? -160 : -200;
    let textsContainer = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginLeft: 10,
      marginRight: 10,
      alignSelf: (this.props.arrow === 'upperLeft' || this.props.arrow === 'upperRight') ? 'flex-start' : 'flex-end',
    };
    textsContainer[(this.props.arrow === 'upperLeft' || this.props.arrow === 'upperRight') ? 'marginBottom' : 'marginTop'] = -20;
    const text = {
      fontWeight: 'bold',
      fontSize: '1.125rem',
      color: Colors.TEXT,
      whiteSpace: 'pre-line',
      textAlign: (this.props.arrow === 'upperLeft' || this.props.arrow === 'lowerLeft' || this.props.arrow === 'lowerLeftAlt') ? 'left' : 'right'
    };
    const touchableContainer = {
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'flex-end',
      cursor: 'pointer',
    };
    return this.props.visible ? <div style={{ zIndex: 10 }}>
      <div style={overlay} />
      <div style={container}>
        <img src={this.Images()[this.props.arrow]} alt="Arrow" height={50} style={{ alignSelf: (this.props.arrow === 'upperLeft' || this.props.arrow === 'upperRight') ? 'flex-start' : 'flex-end' }} />
        <div style={textsContainer}>
          <span style={text}>{this.props.text}</span>
          <div style={touchableContainer} className='d-flex flex-row align-items-center align-self-end cursor-pointer' onClick={() => {
            PlayAudio('Drop');
            if (this.props.onNext) this.props.onNext(this.props.last);
          }}>
            <span style={{ fontWeight: 'bold', fontSize: '1rem', color: Colors.GREEN }}>{this.props.last ? 'Finish!' : 'Next'}</span>
            {!this.props.last && <ChevronRight size={16} color={Colors.GREEN} />}
          </div>
        </div>
      </div>
    </div> : null
  }
}