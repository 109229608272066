import React from "react"
import { Search, X } from "react-feather"
import { Input, InputGroup, InputGroupAddon, Spinner } from "reactstrap"
import { getUser } from "../helpers/AuthHelper"
import Colors from "../helpers/Colors"
import { logError } from "../helpers/ErrorHelper"
import PlayAudio from "../helpers/PlayAudio"
import { getStudents } from "../network/providers/studentsProvider"

export default class StudentSearchInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      studentNameInput: "",
      studentsList: undefined,
      searchingStudents: false,
      studentsListFiltered: [],
      showableList: false,
    }
  }

  searchStudents = () => {
    this.setState({ showableList: true })
    if (!this.state.studentsList && !this.state.searchingStudents) {
      this.setState({ searchingStudents: true })
      getStudents(JSON.parse(getUser())?.user?.class?.id)
        .then((studentsList) =>
          this.setState({ studentsList, searchingStudents: false })
        )
        .catch((e) => logError(e))
    }
  }

  onChange = (studentNameInput) => {
    if (this.state.studentsList && !this.state.searchingStudents) {
      let studentsListFiltered = []
      if (studentNameInput !== "" && this.state.studentsList !== undefined) {
        studentsListFiltered = this.state.studentsList.filter(
          (student) =>
            student.name
              .toLocaleUpperCase()
              .indexOf(studentNameInput.toLocaleUpperCase()) > -1
        )
      }
      this.setState({ studentNameInput, studentsListFiltered })
    }
  }

  render() {
    return (
      <InputGroup
        onFocus={
          this.searchStudents
        } /*onBlur={e => this.setState({ showableList: false })}*/
      >
        <Input
          placeholder="Search Students"
          style={{
            fontSize: "0.75rem",
            borderRadius: "100px 0px 0px 100px",
            backgroundColor: Colors.SEPARATOR,
            width: "50%",
            height: 50,
          }}
          // onKeyDown={(e) => { if (e.key === 'Enter') this.searchStudent() }}
          onChange={(e) => this.onChange(e.target.value)}
          value={this.state.studentNameInput}
        />
        <InputGroupAddon
          addonType="append"
          className="d-flex align-items-center px-1 overflow-hidden"
          style={{
            border: "1px solid #d9d9d9",
            borderRadius: "0px 100px 100px 0px",
            backgroundColor: Colors.SEPARATOR,
            cursor: this.state.studentNameInput && "pointer",
          }}
          onClick={() => this.state.studentNameInput && this.onChange("")}
        >
          {this.state.studentNameInput ? (
            <X size={16} color={Colors.TEXT} />
          ) : (
            <Search size={16} color={Colors.TEXT} />
          )}
        </InputGroupAddon>
        {this.state.showableList &&
          (this.state.searchingStudents ||
            this.state.studentsListFiltered.length > 0) && (
            <div
              className="position-absolute bg-white ml-1 w-75 rounded d-flex flex-column"
              style={{
                zIndex: 5,
                marginTop: 53,
                boxShadow: "10px 10px 30px rgba(52, 52, 52, 0.1)",
                maxHeight: !this.state.searchingStudents && 150,
                overflowY: !this.state.searchingStudents && "auto",
                border: "#bbbb 1px solid",
                borderRaius: "0.5rem !important",
              }}
            >
              {this.state.searchingStudents ? (
                <Spinner
                  color="primary"
                  style={{
                    height: 50,
                    width: 50,
                    alignSelf: "center",
                    margin: 20,
                  }}
                />
              ) : (
                this.state.studentsListFiltered.map((student, i) => (
                  <div
                    key={i}
                    className="py-1 px-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      PlayAudio("Drop")
                      this.setState({
                        studentNameInput: student.name,
                        showableList: false,
                      })
                      if (this.props.onStudentSelect)
                        this.props.onStudentSelect(student, () =>
                          this.onChange("")
                        )
                    }}
                  >
                    <span>{student.name}</span>
                  </div>
                ))
              )}
            </div>
          )}
      </InputGroup>
    )
  }
}
