import Provider from "./Provider"
import { errorHandler } from "./errorHandler"
import { gradesMapper } from "../mappers/gradesMapper"

export const getGrades = () => {
  return new Promise((resolve, reject) => {
    Provider.get("/grades")
      .then((response) => resolve(gradesMapper(response)))
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const changeClassGrade = (class_id, new_grade_id) => {
  const DTO = { class_id, new_grade_id }
  return new Promise((resolve, reject) => {
    Provider.post("/grades/change-class-grade", DTO)
      .then(resolve)
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const changeStudentGrade = (student_id, new_grade_id) => {
  const DTO = { student_id, new_grade_id }
  return new Promise((resolve, reject) => {
    Provider.post("/grades/change-student-grade", DTO)
      .then(resolve)
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}
