import React, { useState } from "react"
import { Search, X } from "react-feather"
import { Input, InputGroup, InputGroupAddon } from "reactstrap"
import Colors from "../helpers/Colors"

export default function Searchbar({ onChange = () => {} }) {
  const [text, setText] = useState("")
  const localChange = (newText) => {
    setText(newText)
    onChange(newText)
  }
  return (
    <InputGroup style={{ width: "67%" }}>
      <Input
        placeholder="Search Students"
        style={{
          fontSize: "0.75rem",
          borderRadius: "100px 0px 0px 100px",
          backgroundColor: Colors.SEPARATOR,
          width: "50%",
          height: 50,
        }}
        onChange={(e) => localChange(e.target.value)}
        value={text}
      />
      <InputGroupAddon
        addonType="append"
        className="d-flex align-items-center px-1 overflow-hidden"
        style={{
          border: "1px solid #d9d9d9",
          borderRadius: "0px 100px 100px 0px",
          backgroundColor: Colors.SEPARATOR,
          cursor: text && "pointer",
        }}
        onClick={() => text && localChange("")}
      >
        {text ? (
          <X size={16} color={Colors.TEXT} />
        ) : (
          <Search size={16} color={Colors.TEXT} />
        )}
      </InputGroupAddon>
    </InputGroup>
  )
}
