import Provider from "./Provider"
import { errorHandler } from "./errorHandler"
import { authMapper, sourcesMapper } from "../mappers/authMapper"
import {
  levelMapper,
  milestonesMapper,
  inviteUserMapper,
} from "../mappers/usersMapper"

export const authUser = (email, password) => {
  return new Promise((resolve, reject) => {
    const DTO = { email, password, device_token: null }
    Provider.post("/auth", DTO)
      .then((response) => resolve(authMapper(response)))
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const resetPassword = (email) => {
  return new Promise((resolve, reject) => {
    Provider.post("/forgotten-password", { email })
      .then((response) => resolve(response))
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const signUp = (
  email,
  password,
  apple_id,
  google_id,
  firstname,
  lastname,
  gender,
  phone,
  school_id,
  name,
  grade_id,
  exam_frecuency,
  days,
  lang
) => {
  return new Promise((resolve, reject) => {
    const DTO = {
      email,
      password,
      apple_id,
      google_id,
      firstname,
      lastname,
      gender,
      phone,
      school_id,
      name,
      grade_id,
      exam_frecuency,
      days,
      lang,
      device_token: null,
    }
    Provider.post("/user/create", DTO)
      .then((response) => resolve(authMapper(response)))
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const signInGoogle = (google_id, email) => {
  return new Promise((resolve, reject) => {
    const DTO = { google_id, email, device_token: null }
    Provider.post("/auth_google", DTO)
      .then((response) => resolve(authMapper(response)))
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getLevel = (class_id) => {
  return new Promise((resolve, reject) => {
    Provider.get(`/user/badges/${class_id}`)
      .then((response) => resolve(levelMapper(response)))
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getMilestones = (class_id) => {
  return new Promise((resolve, reject) => {
    Provider.get(`/user/milestones/${class_id}`)
      .then((response) => resolve(milestonesMapper(response)))
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const editUser = (
  id,
  firstname,
  lastname,
  phone,
  email,
  gender,
  exam_frecuency,
  days,
  class_id
) => {
  const DTO = {
    firstname,
    lastname,
    phone,
    email,
    gender,
    exam_frecuency,
    days,
    class_id,
  }
  return new Promise((resolve, reject) => {
    Provider.put(`users/user/${id}`, DTO)
      .then((response) => resolve(response))
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getInviteUser = (token) => {
  return new Promise((resolve, reject) => {
    Provider.get(`temporary-user/${token}`)
      .then((response) => resolve(inviteUserMapper(response)))
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const invitationSignUp = (
  email,
  password,
  firstname,
  lastname,
  gender,
  role_id
) => {
  return new Promise((resolve, reject) => {
    const DTO = {
      email,
      password,
      firstname,
      lastname,
      gender,
      role_id,
      device_token: null,
    }
    Provider.post("/user/create-by-invitation", DTO)
      .then((response) => resolve(authMapper(response)))
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const prospect = (email) => {
  return new Promise((resolve, reject) => {
    Provider.post("/post-prospect", { email, type: "user" })
      .then((response) => resolve(response))
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getSources = () => {
  return new Promise((resolve, reject) => {
    Provider.get("sources/get-sources")
      .then((response) => resolve(sourcesMapper(response)))
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const postSource = (user_email, source_id, other = null) => {
  const DTO = { user_email, source_id, other }
  return new Promise((resolve, reject) => {
    Provider.post("sources/post-source", DTO)
      .then((response) => resolve(response))
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const deleteAccount = () => {
  return new Promise((resolve, reject) => {
    Provider.post("user/delete-account")
      .then((response) => resolve(response))
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}
