import React from "react"
import { Button as Ripple } from "reactstrap"
import PlayAudio from "../helpers/PlayAudio"

export default class Button extends React.Component {
  onBtnClick = () => {
    if (!this.props.disabled) {
      PlayAudio("Drop")
      if (this.props.onClick) this.props.onClick()
    }
  }
  render() {
    let styles = {
      fontWeight: "bold",
      marginTop: 20,
      alignSelf: "center",
      borderRadius: 100,
      width: "100%",
    }
    if (this.props.style) styles = { ...styles, ...this.props.style }
    return (
      <Ripple
        size="lg"
        style={styles}
        onClick={this.onBtnClick}
        color={
          this.props.disabled
            ? "secondary"
            : this.props.color
            ? this.props.color
            : "info"
        }
        disabled={this.props.disabled ? this.props.disabled : false}
        type={this.props.type ? this.props.type : undefined}
      >
        {this.props.text}
      </Ripple>
    )
  }
}
