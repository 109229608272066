import React from "react"
import Colors from "../helpers/Colors"
import PlayAudio from "../helpers/PlayAudio"
import { Plus } from "react-feather"

export default class Book extends React.Component {
  onBookClick = () => {
    if (this.props.onClick) {
      PlayAudio("Drop")
      this.props.onClick()
    }
  }
  render() {
    return (
      <div
        className="d-flex flex-column"
        onClick={this.onBookClick}
        style={{ ...{ margin: "1rem" }, ...this.props.style }}
      >
        <div
          className="book"
          style={{
            borderWidth: 1,
            borderColor: Colors.GRAY,
            borderRadius: 10,
            maxWidth: this.props.size ? this.props.size : 130,
            cursor: this.props.onClick ? "pointer" : "default",
            position: "relative",
            overflow: "hidden",
            boxShadow: "1px 1px 11px 0px rgba(0,0,0,0.51)",
          }}
        >
          <img
            src={this.props.book.image}
            style={{
              height: this.props.size ? this.props.size : 130,
              width: this.props.size ? this.props.size : 130,
              objectFit: "cover",
            }}
            alt={`Book cover: ${this.props?.book?.title}`}
          />
          <div
            className="d-flex flex-row justify-content-between align-items-center w-100"
            style={{
              position: "absolute",
              bottom: 0,
              padding: 5,
            }}
          >
            <span
              style={{
                borderRadius: 100,
                padding: "2px 5px",
                color: Colors.LIGHTBLUE,
                backgroundColor: "#fff",
                fontSize: "0.75rem",
                height: "fit-content",
              }}
            >
              {this.props.book.lexile}
            </span>
            {this.props.addPlus ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  borderRadius: 100,
                  backgroundColor: Colors.LIGHTBLUE,
                  fontSize: "1rem",
                  textAlign: "center",
                  width: 30,
                  height: 30,
                }}
              >
                <Plus size={20} color="#fff" />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    )
  }
}
