import React from "react"
import { Document, Page } from "react-pdf/dist/esm/entry.webpack"
import { Spinner } from "reactstrap"
import axios from "axios"
import { URL_BACKEND } from "../config"
import { logError } from "../helpers/ErrorHelper"

export default class PDFReader extends React.Component {
  constructor(props) {
    super(props)
    this.bookId = props?.bookId
    this.token = props?.token
    this.noFullScreen = props?.noFullScreen
    this.onLoad = props?.onLoad ? props.onLoad : () => {}
    this.updatePage = props?.updatePage ? props.updatePage : () => {}
    this.isDoublePage = props?.doublePage
    this.book = {}
    this.state = {
      fetchingBook: true,
      pageNumber: 1,
      numPages: null,
      containerWidth: window.innerWidth, //- 30,
      containerHeight: window.innerHeight, //- 30,
      // windowWidth: window.innerWidth,
      // windowHeight: window.innerHeight,
    }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  // updateWindowDimensions() {
  //   this.setState({ loadingSize: true }, () => {
  //     const windowWidth = this.containerElement
  //       ? this.containerElement.clientWidth
  //       : 0
  //     const windowHeight = this.containerElement
  //       ? this.containerElement.clientHeight
  //       : 0
  //     this.setState({ windowWidth, windowHeight, loadingSize: false }, () => {
  //       this.book.renderOnlyOne =
  //         this.isDoublePage || windowHeight > windowWidth // && !this.noFullScreen
  //     })
  //   })
  // }

  updateWindowDimensions() {
    this.setState({ loadingSize: true }, () => {
      const containerWidth = this.containerElement
        ? this.containerElement.clientWidth //- 30
        : 0
      const containerHeight = this.containerElement
        ? this.containerElement.clientHeight //- 30
        : 0
      this.setState(
        { containerWidth, containerHeight, loadingSize: false },
        () => {
          this.book.renderOnlyOne =
            this.isDoublePage || containerHeight > containerWidth // && !this.noFullScreen
        }
      )
    })
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateWindowDimensions)

    if (this.props?.componentPrevPage !== undefined) {
      this.props.componentPrevPage(this.prevPage)
    }
    if (this.props?.componentNextPage !== undefined) {
      this.props.componentNextPage(this.nextPage)
    }

    const provider = axios.create({ baseURL: URL_BACKEND })
    provider.defaults.headers.common["Authorization"] = `Bearer ${this.token}`
    provider
      .get(`${URL_BACKEND}/app-student/get-book/${this.bookId}`)
      .then((response) => {
        this.isDoublePage = response?.data?.data?.double_page
        // if (this.props?.componentDoublePage !== undefined) {
        //   this.props.componentDoublePage(this.isDoublePage)
        // }
        this.book = {
          ...response?.data?.data,
          ...{
            // renderOnlyOne:
            //   !this.isDoublePage
            //   ,// ||              this.state.windowHeight > this.state.windowWidth,
            renderOnlyOne:
              this.isDoublePage ||
              this.state.containerHeight > this.state.containerWidth,
            // file_url: require("../assets/test.pdf"),
          },
        }
        this.updateWindowDimensions()
      })
      .catch((e) => {
        logError(e)
      })
      .finally(() => {
        this.setState({ fetchingBook: false })
      })
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  onDocumentLoadSuccess = (numPages) => {
    this.setState({ numPages: numPages.numPages }, () => {
      this.onLoad(this.state.numPages)
    })
  }

  prevPage = () => {
    if (this.state.pageNumber > 1) {
      this.setState(
        {
          pageNumber:
            this.state.pageNumber -
            (this.book.renderOnlyOne || this.state.pageNumber === 2 ? 1 : 2),
        },
        () => {
          this.updatePage(this.state.pageNumber)
        }
      )
    }
  }

  nextPage = () => {
    if (
      (this.book.renderOnlyOne &&
        this.state.pageNumber < this.state.numPages) ||
      (!this.book.renderOnlyOne &&
        this.state.pageNumber + 1 < this.state.numPages)
    ) {
      this.setState(
        {
          pageNumber:
            this.state.pageNumber +
            (this.book.renderOnlyOne ||
            this.state.pageNumber === 1 ||
            this.state.pageNumber + 1 === this.state.numPages
              ? 1
              : 2),
        },
        () => {
          this.updatePage(this.state.pageNumber)
        }
      )
    }
  }

  renderSpinner = () => (
    <Spinner color="primary" style={{ height: 100, width: 100, margin: 30 }} />
  )

  renderLoading = () => (
    <div
      style={{
        flex: 1,
        height: "100%",
        width: "100%",
        position: "absolute",
        zIndex: 2,
      }}
    >
      {this.renderSpinner()}
    </div>
  )

  renderOverlay = (position) => {
    const diffs = {
      left: {
        styles: {
          left: 0,
        },
        onClick: this.prevPage,
        visible: this.state.pageNumber > 1,
      },
      right: {
        styles: {
          right: 0,
        },
        onClick: this.nextPage,
        visible:
          (this.book?.renderOnlyOne &&
            this.state.pageNumber < this.state.numPages) ||
          (!this.book?.renderOnlyOne &&
            this.state.pageNumber + 1 < this.state.numPages),
      },
    }

    const styles = {
      flex: 2,
      height: "100%",
      width: "50%",
      position: "absolute",
      top: 0,
      zIndex: 1,
    }
    return (
      <div
        style={{
          ...styles,
          ...diffs[position].styles,
          ...{ cursor: diffs[position].visible ? "pointer" : "default" },
        }}
        onClick={diffs[position].visible ? diffs[position].onClick : undefined}
      >
        {this.showHighlights ? (
          <div
            className="pulse"
            style={{
              ...diffs[position].styles,
              ...{
                width: 80,
                height: 80,
                borderRadius: 80,
                position: "absolute",
                bottom: 0,
                margin: 15,
                zIndex: -1,
                transition: "opacity 1s ease-out",
                opacity:
                  diffs[position].visible && this.state.showHighlights ? 1 : 0,
                backgroundColor: "#fff7",
              },
            }}
          />
        ) : null}
      </div>
    )
  }

  renderPage = (pageNumber, renderBoth, index) => {
    // const finalWidth = this.props.web
    //   ? // this.props.maxHeight &&
    //     // this.props.maxWidth &&
    //     // mobileWidth < this.props.maxWidth &&
    //     // this.state.containerHeight < this.props.maxHeight
    //     // ? mobileWidth
    //     // : undefined
    //     this.props.maxHeight &&
    //     this.state.containerHeight >= this.props.maxHeight //&&
    //     ? // mobileWidth < this.props.maxWidth
    //       undefined
    //     : mobileWidth
    //   : mobileWidth

    // console.log("this.props.maxHeight", this.props.maxHeight)
    // console.log("this.props.maxWidth", this.props.maxWidth)
    // console.log("mobileWidth", mobileWidth)
    // console.log("this.state.containerHeight", this.state.containerHeight)

    const calculateSize = (width, height) => {
      let pageWidth = undefined
      let pageHeight = undefined
      // if (renderBoth) {
      //   if (width * 2 > height) {
      //     pageWidth = width
      //   } else {
      //     pageHeight = height
      //   }
      // } else if (width <= height) {
      //   pageHeight = height
      // } else {
      //   pageWidth = width
      // }
      const w1 = width * (renderBoth ? 2 : 1)
      const h1 = height
      if (w1 / h1 > this.props.maxWidth / this.props.maxHeight) {
        pageWidth = width
      } else {
        pageHeight = height
      }

      let scale = 0.99
      if (pageWidth) {
        scale = this.props.maxWidth / (pageWidth * (renderBoth ? 2 : 1))
      } else if (pageHeight) {
        scale = this.props.maxHeight / pageHeight
      }

      this.setState({ pageWidth, pageHeight, scale })
    }

    return (
      <div key={index}>
        <Page
          key={index}
          pageNumber={pageNumber}
          loading={this.renderSpinner()}
          width={this.state.pageWidth}
          height={this.state.pageHeight}
          scale={this.state.scale}
          onLoadSuccess={(page) => {
            calculateSize(page?.width, page?.height)
          }}
        />
      </div>
    )
  }

  renderPages = (pageNumber, renderBoth) => {
    return [
      this.renderPage(pageNumber, renderBoth, 1),
      renderBoth ? this.renderPage(pageNumber + 1, renderBoth, 2) : null,
    ]
  }

  render() {
    const renderBoth =
      !this.book?.renderOnlyOne &&
      this.state.pageNumber !== 1 &&
      this.state.pageNumber + 1 <= this.state.numPages
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          minHeight: this.noFullScreen ? undefined : "100vh",
        }}
        ref={(containerElement) => {
          this.containerElement = containerElement
        }}
      >
        {this.state.fetchingBook ? (
          this.renderSpinner()
        ) : (
          <Document
            className="d-flex flex-row justify-content-center flex-wrap w-100 position-relative"
            file={this.book?.file_url}
            loading={this.renderSpinner()}
            onLoadSuccess={this.onDocumentLoadSuccess}
          >
            {this.state.loadingSize ? this.renderLoading() : null}
            {this.renderOverlay("left")}
            {this.renderOverlay("right")}
            {this.renderPages(this.state.pageNumber, renderBoth)}
          </Document>
        )}
      </div>
    )
  }
}
