import React from "react"
import {
  setActiveClass,
  login,
  updateLocalClasses,
  logout,
} from "../helpers/AuthHelper"
import Colors from "../helpers/Colors"
import { Button, Dropdown, SideModal, ToriModal } from "../components"
import { frecuencyList, genderLabels, langs } from "../helpers/Constants"
import { Input, Label, Spinner } from "reactstrap"
import { Check, ChevronsRight, Edit, Plus } from "react-feather"
import PlayAudio from "../helpers/PlayAudio"
import { deleteAccount, editUser } from "../network/providers/authProvider"
import {
  getClasses,
  getRoles,
  inviteUser,
  putClassLang,
} from "../network/providers/classProvider"
import { logError, parseError } from "../helpers/ErrorHelper"
import {
  changeClassGrade,
  getGrades,
} from "../network/providers/gradesProvider"

export default class SettingsComponents extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...{
        editing: false,
        loadingSettings: false,
        classes: undefined,
        addTeacherModalVisible: false,
        roles: undefined,
        teacherAddedModalVisible: false,
        validationErrors: [],
        closeAccountModalVisible: false,
        grades: [],
        gradesLoading: true,
        changeGradeWarningModalVisible: false,
        newLang: langs[0].value,
        changeLangModalVisible: false,
        changeLangWarningModalVisible: false,
      },
      ...this.baseEdits(),
    }
    this.state = { ...this.state, ...this.baseInvite() }
  }

  baseEdits() {
    return {
      editFirstname: JSON.parse(this.props.userTR).user?.firstname,
      editLastname: JSON.parse(this.props.userTR).user?.lastname,
      editEmail: JSON.parse(this.props.userTR).user?.email,
      editGender: JSON.parse(this.props.userTR).user?.gender,
      editPhone: JSON.parse(this.props.userTR).user?.phone,
      editFrecuency: JSON.parse(this.props.userTR).user?.class?.assessmentsTime,
      editClass: JSON.parse(this.props.userTR).user?.class?.id,
      daysSelected: JSON.parse(this.props.userTR).user?.class?.daysSelected,
      editGrade: undefined,
    }
  }

  baseInvite() {
    return {
      inviteEmail: "",
      inviteRole: this.state.roles ? this.state.roles[0].id : undefined,
    }
  }

  onError = (e) => {
    //TODO: Fix function - No hace nada
    // this.setState({
    //   loadingData: false,
    //   networkError:
    //     e.response && e.response.data && e.response.data.message
    //       ? e.response.data.message
    //       : "Unknown error",
    // })
    logError(e, 2)
  }

  saveEditedUser = () => {
    this.setState(
      {
        editing: false,
        loadingSettings: true,
        changeGradeWarningModalVisible: false,
      },
      async () => {
        await editUser(
          JSON.parse(this.props.userTR).user?.id,
          this.state.editFirstname,
          this.state.editLastname,
          this.state.editPhone,
          this.state.editEmail,
          this.state.editGender,
          frecuencyList[0]?.value, //this.state.editFrecuency,
          this.state.daysSelected,
          this.state.editClass
        )
        let user = setActiveClass(this.state.editClass).user
        user.firstname = this.state.editFirstname
        user.lastname = this.state.editLastname
        user.phone = this.state.editPhone
        user.email = this.state.editEmail
        user.gender = this.state.editGender
        user.class.assessmentsTime = this.state.editFrecuency
        user.class.daysSelected = this.state.daysSelected
        if (this.state.editGrade !== undefined) {
          const newGrade = this.state.grades.find(
            (f) => f.value === this.state.editGrade
          )
          if (newGrade) {
            user.class.grade = { id: newGrade.id, name: newGrade.name }
            await changeClassGrade(this.state.editClass, newGrade.id)
          }
        }
        let newUser = JSON.parse(this.props.userTR)
        newUser.user = user
        login(newUser)
      }
    )
  }

  changeLang = () => {
    if (this.state.newLang) {
      this.setState(
        {
          editing: false,
          loadingSettings: true,
          changeLangWarningModalVisible: false,
          changeLangModalVisible: false,
        },
        () => {
          putClassLang(this.state.editClass, this.state.newLang)
            .then(() => {
              this.updateClasses(() => {
                setActiveClass(this.state.editClass)
                window.location.reload()
              })
            })
            .catch((e) => {
              this.onError(e)
              this.setState({ loadingSettings: false })
            })
        }
      )
    }
  }

  updateClasses(callback = () => {}) {
    getClasses()
      .then((classes) => {
        updateLocalClasses(classes)
        this.setState({ classes }, callback)
      })
      .catch(this.onError)
  }

  onIconPress = () => {
    if (this.state.editing) {
      if (this.state.editGrade !== undefined) {
        this.setState({ changeGradeWarningModalVisible: true })
      } else {
        this.saveEditedUser()
      }
    } else {
      this.setState(
        { editing: true, loadingSettings: this.state.classes === undefined },
        async () => {
          if (this.state.classes === undefined) {
            this.updateClasses(() => this.setState({ loadingSettings: false }))
            getGrades()
              .then((grades) => this.setState({ grades, gradesLoading: false }))
              .catch(this.onError)
          }
        }
      )
    }
  }

  validate() {
    let errors = []
    if (!this.state.inviteEmail) {
      errors.push("Insert the teacher's email!")
    }
    if (!this.state.inviteRole) {
      errors.push("Select a role!")
    }
    return errors
  }

  sendInvite = () => {
    let errors = this.validate()
    if (errors.length === 0) {
      inviteUser(
        this.state.editClass,
        this.state.inviteEmail,
        this.state.inviteRole
      )
        .then(() => {
          this.setState({
            addTeacherModalVisible: false,
            teacherAddedModalVisible: true,
          })
        })
        .catch((e) => {
          logError(e)
          // console.log("ERROR", e.response.data.errors[0].msg)
          this.setState({
            loadingData: false,
            validationErrors: [parseError(e)],
            // validationErrors: [e.response.data.errors[0].msg],
          })
        })
    } else {
      this.setState({ validationErrors: errors })
    }
  }

  closeTeacherAdded = () => {
    this.setState({
      ...{ teacherAddedModalVisible: false, addTeacherModalVisible: false },
      ...this.baseInvite(),
    })
  }

  closeAccount = () => {
    PlayAudio("Drop")
    deleteAccount().then(() => {
      logout()
    })
  }

  onDayPressed(day) {
    PlayAudio("Drop")
    let daysSelected = this.state.daysSelected
    const selectionLimit =
      this.state.editFrecuency === "TWICE_WEEK"
        ? daysSelected.filter((day) => day).length === 2
        : daysSelected.filter((day) => day).length === 1
    if (selectionLimit) {
      if (this.state.editFrecuency === "TWICE_WEEK") {
        daysSelected = [false, false, false, false, false]
        this.setState({ daysSelected })
      } else {
        daysSelected = [false, false, false, false, false]
        daysSelected[day] = true
        this.setState({ daysSelected })
      }
    } else {
      daysSelected = [...this.state.daysSelected]
      daysSelected[day] = true
      this.setState({ daysSelected })
    }
  }

  renderWeekDaysButtons() {
    return this.state.daysSelected.map((selected, index) => {
      const label = () => {
        switch (index) {
          case 0:
            return "M"
          case 1:
            return "T"
          case 2:
            return "W"
          case 3:
            return "T"
          case 4:
            return "F"
          default:
            return undefined
        }
      }
      const clickeable =
        this.state.editing &&
        this.state.classes.find((c) => c.id === this.state.editClass)
          ?.status !== "CLOSED"
      return (
        <Label
          key={index}
          check
          onClick={() => {
            if (clickeable) {
              this.onDayPressed(index)
            }
          }}
          className="d-flex justify-content-center align-items-center p-0"
          style={{
            fontWeight: "bold",
            fontSize: "0.75rem",
            color: "#fff",
            backgroundColor: selected
              ? Colors.PRIMARY
              : Colors.ACHIEVEMENTS_GRAY,
            width: 50,
            height: 50,
            borderRadius: 100,
            cursor: clickeable && "pointer",
            textAlign: "center",
          }}
        >
          {label()}
        </Label>
      )
    })
  }

  renderSettingsModal() {
    return this.state.loadingSettings ? (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "75vh" }}
      >
        <Spinner
          color="primary"
          style={{ height: 100, width: 100, alignSelf: "center" }}
        />
      </div>
    ) : (
      <div>
        <div className="mb-2">
          <span style={{ fontWeight: "bold", fontSize: "1rem" }}>
            Your Information
          </span>
          <div className="d-flex flex-row my-1">
            <div className="d-flex flex-column w-50 mr-1">
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "0.75rem",
                  color: Colors.GREEN,
                }}
              >
                First Name
              </span>
              {this.state.editing ? (
                <Input
                  id="settings-firstname"
                  name="tr-firstname"
                  placeholder={JSON.parse(this.props.userTR).user?.firstname}
                  style={{ fontSize: "0.75rem" }}
                  onChange={(e) =>
                    this.setState({ editFirstname: e.target.value })
                  }
                  value={this.state.editFirstname}
                />
              ) : (
                <span style={{ fontSize: "0.75rem" }}>
                  {JSON.parse(this.props.userTR).user?.firstname}
                </span>
              )}
            </div>
            <div className="d-flex flex-column w-50 mr-1">
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "0.75rem",
                  color: Colors.GREEN,
                }}
              >
                Last Name
              </span>
              {this.state.editing ? (
                <Input
                  id="settings-lastname"
                  name="tr-lastname"
                  placeholder={JSON.parse(this.props.userTR).user?.lastname}
                  style={{ fontSize: "0.75rem" }}
                  onChange={(e) =>
                    this.setState({ editLastname: e.target.value })
                  }
                  value={this.state.editLastname}
                />
              ) : (
                <span style={{ fontSize: "0.75rem" }}>
                  {JSON.parse(this.props.userTR).user?.lastname}
                </span>
              )}
            </div>
          </div>
          <div className="d-flex flex-row mb-1">
            <div className="d-flex flex-column w-50 mr-1">
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "0.75rem",
                  color: Colors.GREEN,
                }}
              >
                Email
              </span>
              {this.state.editing ? (
                <Input
                  id="settings-email"
                  name="tr-email"
                  placeholder={JSON.parse(this.props.userTR).user?.email}
                  style={{ fontSize: "0.75rem" }}
                  onChange={(e) => this.setState({ editEmail: e.target.value })}
                  value={this.state.editEmail}
                />
              ) : (
                <span style={{ fontSize: "0.75rem" }}>
                  {JSON.parse(this.props.userTR).user?.email}
                </span>
              )}
            </div>
            <div className="d-flex flex-column w-50 mr-1">
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "0.75rem",
                  color: Colors.GREEN,
                }}
              >
                Gender
              </span>
              {this.state.editing ? (
                <Dropdown
                  name="tr-gender-selection"
                  value={this.state.editGender}
                  onChange={(e) =>
                    this.setState({ editGender: e.target.value })
                  }
                >
                  {Object.keys(genderLabels).map((key, index) => (
                    <option key={index} value={key}>
                      {genderLabels[key]}
                    </option>
                  ))}
                </Dropdown>
              ) : JSON.parse(this.props.userTR).user?.gender ? (
                <span style={{ fontSize: "0.75rem" }}>
                  {genderLabels[JSON.parse(this.props.userTR).user.gender]}
                </span>
              ) : null}
            </div>
          </div>
          <div className="d-flex flex-row mb-1">
            <div className="d-flex flex-column w-50 mr-1">
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "0.75rem",
                  color: Colors.GREEN,
                }}
              >
                Phone
              </span>
              {this.state.editing ? (
                <Input
                  id="settings-phone"
                  name="tr-phone"
                  placeholder={JSON.parse(this.props.userTR).user?.phone}
                  style={{ fontSize: "0.75rem" }}
                  onChange={(e) => this.setState({ editPhone: e.target.value })}
                  value={this.state.editPhone}
                />
              ) : (
                <span style={{ fontSize: "0.75rem" }}>
                  {JSON.parse(this.props.userTR).user?.phone}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="mb-2">
          <span style={{ fontWeight: "bold", fontSize: "1rem" }}>
            School Information
          </span>
          <div className="d-flex flex-row my-1">
            <div className="d-flex flex-column w-50 mr-1">
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "0.75rem",
                  color: Colors.GREEN,
                }}
              >
                Name
              </span>
              <span style={{ fontSize: "0.75rem" }}>
                {JSON.parse(this.props.userTR).user?.school?.name}
              </span>
            </div>
            <div className="d-flex flex-column w-50 mr-1">
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "0.75rem",
                  color: Colors.GREEN,
                }}
              >
                District
              </span>
              <span style={{ fontSize: "0.75rem" }}>
                {JSON.parse(this.props.userTR).user?.school?.district?.name}
              </span>
            </div>
          </div>
        </div>
        <div className="mb-2">
          <div className="d-flex flex-row justify-content-between">
            <div className="d-flex flex-row align-items-center justify-content-between w-100">
              <span style={{ fontWeight: "bold", fontSize: "1rem" }}>
                Classroom Settings
              </span>
              {!this.state.editing &&
              JSON.parse(this.props.userTR).user?.class?.status === "OPEN" ? (
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: Colors.GREEN,
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    this.setState({ changeLangModalVisible: true })
                  }
                >
                  Change language
                </span>
              ) : null}
            </div>
            {this.state.editing &&
              this.state.classes.find((c) => c.id === this.state.editClass)
                ?.status !== "CLOSED" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    PlayAudio("Drop")
                    if (!this.state.roles) {
                      this.setState({ loadingSettings: true }, () => {
                        getRoles()
                          .then((roles) => {
                            this.setState(
                              { roles, inviteRole: roles[0].id },
                              () =>
                                this.setState({
                                  loadingSettings: false,
                                  addTeacherModalVisible: true,
                                })
                            )
                          })
                          .catch(this.onError)
                      })
                    } else {
                      this.setState({ addTeacherModalVisible: true })
                    }
                  }}
                >
                  <Plus size={16} color={Colors.GREEN} />
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "0.875rem",
                      color: Colors.GREEN,
                    }}
                  >
                    Add Teacher
                  </span>
                </div>
              )}
          </div>
          <div className="d-flex flex-row my-1">
            <div className="d-flex flex-column w-50 mr-1">
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "0.75rem",
                  color: Colors.GREEN,
                }}
              >
                Class Name
              </span>
              {this.state.editing ? (
                <Dropdown
                  name="tr-class-selection"
                  value={this.state.editClass}
                  onChange={(e) => {
                    const selectedClass = this.state.classes.find(
                      (c) => c.id === e.target.value
                    )
                    this.setState({
                      editClass: e.target.value,
                      editFrecuency: selectedClass?.assessmentsTime,
                      daysSelected: selectedClass?.daysSelected,
                      editGrade: selectedClass?.grade?.id,
                    })
                  }}
                >
                  {this.state.classes.map((c, index) => (
                    <option key={index} value={c.id}>
                      {c.name}
                    </option>
                  ))}
                </Dropdown>
              ) : (
                <span style={{ fontSize: "0.75rem" }}>
                  {JSON.parse(this.props.userTR).user?.class?.name}
                </span>
              )}
            </div>
            <div className="d-flex flex-column w-50 mr-1">
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "0.75rem",
                  color: Colors.GREEN,
                }}
              >
                Grade
              </span>
              {this.state.editing ? (
                this.state.classes.find((c) => c.id === this.state.editClass)
                  ?.status === "CLOSED" ? (
                  <span style={{ fontSize: "0.75rem" }}>
                    {
                      this.state.grades.find(
                        (f) => f.value === this.state.editGrade
                      )?.label
                    }
                  </span>
                ) : (
                  <Dropdown
                    name="tr-grade-selection"
                    value={
                      this.state.editGrade !== undefined
                        ? this.state.editGrade
                        : this.state.classes
                        ? this.state.classes.find(
                            (c) => c.id === this.state.editClass
                          )?.grade?.id
                        : JSON.parse(this.props.userTR).user?.class?.grade?.id
                    }
                    onChange={(e) => {
                      this.setState({ editGrade: e.target.value })
                    }}
                  >
                    {this.state.grades.map((f, index) => (
                      <option key={index} value={f.value}>
                        {f.label}
                      </option>
                    ))}
                  </Dropdown>
                )
              ) : (
                <span style={{ fontSize: "0.75rem" }}>
                  {this.state.classes
                    ? this.state.classes.find(
                        (c) => c.id === this.state.editClass
                      )?.grade?.name
                    : JSON.parse(this.props.userTR).user?.class?.grade?.name}
                </span>
              )}
            </div>
          </div>
          <div className="d-flex flex-row mb-1">
            <div className="d-flex flex-column w-50 mr-1">
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "0.75rem",
                  color: Colors.GREEN,
                }}
              >
                Assessments
              </span>
              {/* {this.state.editing ? (
                this.state.classes.find((c) => c.id === this.state.editClass)
                  ?.status === "CLOSED" ? (
                  <span style={{ fontSize: "0.75rem" }}>
                    {
                      frecuencyList.find(
                        (f) => f.value === this.state.editFrecuency
                      )?.label
                    }
                  </span>
                ) : (
                  <Dropdown
                    name="tr-frecuency-selection"
                    value={this.state.editFrecuency}
                    onChange={(e) =>
                      this.setState({ editFrecuency: e.target.value })
                    }
                  >
                    {frecuencyList.map((f, index) => (
                      <option key={index} value={f.value}>
                        {f.label}
                      </option>
                    ))}
                  </Dropdown>
                )
              ) : ( */}
              <span style={{ fontSize: "0.75rem" }}>
                {/* {
                    frecuencyList.find(
                      (f) =>
                        f.value ===
                        JSON.parse(this.props.userTR).user.class
                          ?.assessmentsTime
                    )?.label
                  } */}
                {frecuencyList[0]?.label}
              </span>
              {/* )} */}
            </div>
            <div className="d-flex flex-column w-50 mr-1">
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "0.75rem",
                  color: Colors.GREEN,
                }}
              >
                Role
              </span>
              <span style={{ fontSize: "0.75rem" }}>
                {this.state.classes
                  ? this.state.classes.find(
                      (c) => c.id === this.state.editClass
                    )?.role
                  : JSON.parse(this.props.userTR).user?.class?.role}
              </span>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-around">
            {this.state.daysSelected && this.renderWeekDaysButtons()}
          </div>
        </div>
        <div className="mb-2 d-flex flex-row justify-content-center">
          <span
            style={{
              fontWeight: "bold",
              fontSize: "1rem",
              color: Colors.RED,
              cursor: "pointer",
            }}
            onClick={() => this.setState({ closeAccountModalVisible: true })}
          >
            Delete account
          </span>
        </div>
      </div>
    )
  }

  render() {
    return [
      <SideModal
        key="settingsModal"
        visible={this.props.visible}
        closeModal={() => {
          if (this.props.closeSettings) this.props.closeSettings()
          this.setState({ ...{ editing: false }, ...this.baseEdits() })
        }}
        title="Account Settings"
        rightIcon={
          JSON.parse(this.props.userTR).user?.class?.status ===
          "CLOSED" ? null : this.state.editing ? (
            <Check size={18} color="#fff" />
          ) : (
            <Edit size={18} color="#fff" />
          )
        }
        rightIconText={this.state.editing ? "Save" : "Edit"}
        rightIconPress={this.onIconPress}
      >
        {this.renderSettingsModal()}
      </SideModal>,
      <ToriModal
        key="addTeacherToClassModal"
        title="Add teacher"
        visible={this.state.addTeacherModalVisible}
        closeModal={() => this.setState({ addTeacherModalVisible: false })}
      >
        {/*
          let email = req.body.email          this.state.inviteEmail
          let class_id = req.body.class_id    this.state.editClass
          let role = req.body.role_id         this.state.inviteRole
        */}
        <div className="mb-2 d-flex flex-column w-100">
          <span
            style={{
              fontWeight: "bold",
              fontSize: "0.875rem",
              color: Colors.GREEN,
              textAlign: "start",
            }}
          >
            Class name
          </span>
          <span style={{ fontSize: "0.75rem", textAlign: "start" }}>
            {this.state.classes &&
              this.state.classes.find((c) => c.id === this.state.editClass)
                ?.name}
          </span>
        </div>
        <div className="mb-2 d-flex flex-column w-100">
          <span
            style={{
              fontWeight: "bold",
              fontSize: "0.875rem",
              color: Colors.GREEN,
              textAlign: "start",
            }}
          >
            Email
          </span>
          <Input
            name="tr-email"
            placeholder="teacher@email.com"
            style={{ fontSize: "0.75rem" }}
            onChange={(e) => this.setState({ inviteEmail: e.target.value })}
            value={this.state.inviteEmail}
          />
        </div>
        <div className="mb-1 d-flex flex-column w-100">
          <span
            style={{
              fontWeight: "bold",
              fontSize: "0.875rem",
              color: Colors.GREEN,
              textAlign: "start",
            }}
          >
            Role
          </span>
          <Dropdown
            name="tr-role-selection"
            style={{ fontSize: "0.75rem" }}
            onChange={(e) => this.setState({ inviteRole: e.target.value })}
          >
            {this.state.roles !== undefined &&
              this.state.roles.map((r, index) => (
                <option key={index} value={r.id}>
                  {r.name}
                </option>
              ))}
          </Dropdown>
        </div>
        <Button
          text="Invite!"
          style={{ marginBottom: 30 }}
          onClick={this.sendInvite}
        />
      </ToriModal>,
      <ToriModal
        key="teacherAddingValidationErrors"
        visible={this.state.validationErrors.length > 0}
        closeModal={() => this.setState({ validationErrors: [] })}
      >
        {this.state.validationErrors.map((e, i) => (
          <p key={i} style={{ fontSize: "1rem" }}>
            {e}
          </p>
        ))}
        <Button
          text="OK"
          onClick={() => this.setState({ validationErrors: [] })}
        />
      </ToriModal>,
      <ToriModal
        key="teacherAddedToClassModal"
        title="Teacher invited"
        visible={this.state.teacherAddedModalVisible}
        closeModal={this.closeTeacherAdded}
      >
        <p>{`${this.state.inviteEmail} was invited to '${
          this.state.classes &&
          this.state.classes.find((c) => c.id === this.state.editClass)?.name
        }' as a ${
          this.state.roles &&
          this.state.roles.find((r) => r.id === this.state.inviteRole)?.name
        }`}</p>
        <Button
          text="OK!"
          style={{ marginBottom: 30 }}
          onClick={this.closeTeacherAdded}
        />
      </ToriModal>,
      <ToriModal
        key="closeAccountModal"
        visible={this.state.closeAccountModalVisible}
        closeModal={() => this.setState({ closeAccountModalVisible: false })}
      >
        <p style={{ fontSize: "1rem" }}>This action is irreversible!</p>
        <p style={{ fontSize: "1rem" }}>
          Once you delete the account, you won't be able to access it or make
          any further changes.
        </p>
        <Button
          text="Nevermind! I will not delete this account!"
          onClick={() => this.setState({ closeAccountModalVisible: false })}
        />
        <span
          style={{
            fontWeight: "bold",
            fontSize: "0.875rem",
            color: Colors.RED,
            cursor: "pointer",
            marginTop: 20,
          }}
          onClick={this.closeAccount}
        >
          I'm sure! I want to delete this account permanently
        </span>
      </ToriModal>,
      <ToriModal
        key="changeLang"
        title="Choose the language for this class' help guides"
        visible={this.state.changeLangModalVisible}
        closeModal={() => this.setState({ changeLangModalVisible: undefined })}
      >
        <div className="d-flex flex-row align-items-center justify-content-center">
          <h4 className="m-0">
            {
              langs.find(
                (l) =>
                  l.value ===
                  JSON.parse(this.props.userTR).user?.class?.default_lang
              )?.label
            }
          </h4>
          <ChevronsRight
            size={20}
            color={Colors.GREEN}
            style={{ margin: "1rem 3rem" }}
          />
          <Dropdown
            id="lang"
            name="tr-lang"
            onChange={(e) => this.setState({ newLang: e.target.value })}
            value={this.state.newLang}
          >
            {langs.map((l) => (
              <option key={l.value} value={l.value}>
                {l.label}
              </option>
            ))}
          </Dropdown>
        </div>

        <Button
          text="Change"
          onClick={() => this.setState({ changeLangWarningModalVisible: true })}
          disabled={!this.state.newLang}
        />
      </ToriModal>,
      <ToriModal
        key="changeLangWarning"
        visible={this.state.changeLangWarningModalVisible}
        closeModal={() =>
          this.setState({ changeLangWarningModalVisible: false })
        }
      >
        <p style={{ fontSize: "1rem" }}>
          Warning: Changing your class language will automatically change every
          student's language.
        </p>
        <p style={{ fontSize: "1rem" }}>Are you sure you want to continue?</p>
        <Button text="Yes, I'm sure!" onClick={() => this.changeLang()} />
        <span
          style={{
            fontWeight: "bold",
            fontSize: "0.875rem",
            color: Colors.LIGHTBLUE,
            cursor: "pointer",
            marginTop: 20,
          }}
          onClick={() =>
            this.setState({ changeLangWarningModalVisible: false })
          }
        >
          Cancel
        </span>
      </ToriModal>,
      <ToriModal
        key="changeGradeWarning"
        visible={this.state.changeGradeWarningModalVisible}
        closeModal={() =>
          this.setState({ changeGradeWarningModalVisible: false })
        }
      >
        <p style={{ fontSize: "1rem" }}>
          Warning: Changing your class grade level will automatically change
          every student's grade level.
        </p>
        <p style={{ fontSize: "1rem" }}>Are you sure you want to continue?</p>
        <Button text="Yes, I'm sure!" onClick={() => this.saveEditedUser()} />
        <span
          style={{
            fontWeight: "bold",
            fontSize: "0.875rem",
            color: Colors.LIGHTBLUE,
            cursor: "pointer",
            marginTop: 20,
          }}
          onClick={() =>
            this.setState({ changeGradeWarningModalVisible: false })
          }
        >
          Cancel
        </span>
      </ToriModal>,
    ]
  }
}
