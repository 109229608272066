import React from "react"

const ICONS = {
  calendar: ({ color, textColor, size, text }) => (
    // <?xml version="1.0" encoding="utf-8"?>
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      // fill="{color}"
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:bx="https://boxy-svg.com"
    >
      {/* <defs>
        <style bx:fonts="Nunito">
          @import
          url(https://fonts.googleapis.com/css2?family=Nunito%3Aital%2Cwght%400%2C200%3B0%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C800%3B0%2C900%3B1%2C200%3B1%2C300%3B1%2C400%3B1%2C500%3B1%2C600%3B1%2C700%3B1%2C800%3B1%2C900&amp;display=swap);
        </style>
      </defs> */}
      <path
        d="M 4 0.5 C 4 0.115 3.583 -0.125 3.25 0.067 C 3.095 0.156 3 0.321 3 0.5 L 3 1 L 2 1 C 0.895 1 0 1.895 0 3 L 0 4 L 16 4 L 16 3 C 16 1.895 15.105 1 14 1 L 13 1 L 13 0.5 C 13 0.115 12.583 -0.125 12.25 0.067 C 12.095 0.156 12 0.321 12 0.5 L 12 1 L 4 1 L 4 0.5 Z M 16 14 L 16 5 L 0 5 L 0 14 C 0 15.105 0.895 16 2 16 L 14 16 C 15.105 16 16 15.105 16 14 Z"
        transform="matrix(1, 0, 0, 1, 2.220446049250313e-16, 0)"
        fill={color}
      />
      <text
        // style={`fill: ${textColor}; font-size: 5px; white-space: pre; text-anchor: middle;`}
        x="8.094"
        y="11.909"
        transform="matrix(1, 0, 0, 1, 2.220446049250313e-16, 0)"
        fill={textColor}
        fontSize="5px"
        textAnchor="middle"
      >
        {text}
        {/* <p>
        </p>
        <p>asd</p> */}
      </text>
    </svg>
  ),
}

export default function CustomIcon({
  icon,
  color = "#000",
  size = "1rem",
  textColor = "#fff",
  text = "",
}) {
  return ICONS[icon]({ color, textColor, size, text })
}
