import React from "react"
import Colors from "../helpers/Colors"
import {
  Book,
  Clock,
  CheckCircle,
  X,
  Check,
  AlertCircle,
  Plus,
  MessageSquare,
} from "react-feather"
import { Row } from "reactstrap"
import PlayAudio from "../helpers/PlayAudio"

export default class EventCard extends React.Component {
  parseIcon(type) {
    let backgroundColor, icon
    if (type === "reading") {
      backgroundColor = Colors.ICON_BACKGROUND_ORANGE
      icon = <Book size={20} color={Colors.ORANGE} />
    }
    if (type === "exam") {
      backgroundColor = Colors.ICON_BACKGROUND_VIOLET
      icon = <Clock size={20} color={Colors.PURPLE} />
    }
    if (type === "decoding") {
      backgroundColor = Colors.ICON_BACKGROUND_LIGHTBLUE
      icon = <Clock size={20} color={Colors.LIGHTBLUE} />
    }
    if (type === "vocabulary") {
      backgroundColor = Colors.ICON_BACKGROUND_LIGHTBLUE
      icon = <Book size={20} color={Colors.LIGHTBLUE} />
    }
    if (type === "conversation") {
      backgroundColor = Colors.ICON_BACKGROUND_RED
      icon = <MessageSquare size={20} color={Colors.RED} />
    }

    if (type === "plus") {
      backgroundColor = "transparent"
      icon = <Plus size={20} color={Colors.GREEN} />
    }

    return (
      <div
        style={{ borderRadius: 100, margin: 12, padding: 10, backgroundColor }}
      >
        {icon}
      </div>
    )
  }
  // parseIcon(type) {
  //   if (type === 'exam') {
  //     return (
  //       <Block style={[styles.roundBackgroundEventIcon, { backgroundColor: materialTheme.COLORS.ICON_BACKGROUND_VIOLET }]}>
  //         <Icon name={'timer'} family={'material'} size={24} color={materialTheme.COLORS.PURPLE} />
  //       </Block>
  //     )
  //   }
  //   if (type === 'reading') {
  //     return (
  //       <Block style={[styles.roundBackgroundEventIcon, { padding: 10, backgroundColor: materialTheme.COLORS.ICON_BACKGROUND_ORANGE }]}>
  //         <Icon name={'book'} family={'feather'} size={20} color={materialTheme.COLORS.ORANGE} />
  //       </Block>
  //     )
  //   }
  //   if (type === 'student') {
  //     return (
  //       <Block style={{ padding: 13 }}>
  //         <RandomAvatar name={this.props.title} size={18} />
  //       </Block>
  //     )
  //   }
  //   return null;
  // }

  getMark(done) {
    if (done)
      return (
        <Check
          size={20}
          color={Colors.GREEN}
          style={{ right: 30, position: "absolute" }}
        />
      )
    return (
      <AlertCircle
        size={20}
        color={Colors.GREEN}
        style={{ right: 30, position: "absolute" }}
      />
    )
  }

  getTrackIndicator(track) {
    let color =
      track === -1 ? Colors.RED : track === 0 ? Colors.LIGHTBLUE : Colors.GREEN
    return (
      <Row
        style={{
          display: "flex",
          alignItems: "center",
          alignSelf: "flex-start",
          padding: 10,
          position: "absolute",
          right: 0,
        }}
      >
        <span
          style={{
            fontWeight: "bold",
            fontSize: "0.75rem",
            color,
            textAlign: "right",
            paddingRight: 5,
          }}
        >
          {track === -1 ? "Below" : track === 0 ? "On track" : "Above"}
        </span>
        <div style={{ width: 8, height: 8, backgroundColor: color }} />
      </Row>
    )
  }

  getRemovableBtn() {
    return (
      <div
        onClick={() => {
          this.props.onDelete()
        }}
        style={{
          borderRadius: 100,
          height: 25,
          width: 25,
          margin: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          right: 10,
          backgroundColor: this.props.deletedStudent
            ? Colors.ICON_BACKGROUND_GREEN
            : Colors.ICON_BACKGROUND_RED,
        }}
      >
        {this.props.deletedStudent ? (
          <CheckCircle size={15} color={Colors.GREEN} />
        ) : (
          <X size={15} color={Colors.RED} />
        )}
      </div>
    )
  }

  renderCard() {
    return (
      <>
        {/* {this.props.special &&
          <Block style={{ position: 'absolute', width: '120%', height: '100%', backgroundColor: materialTheme.COLORS.ICON_BACKGROUND_ORANGE }}>
            <Image source={Images.ConfettiGif} style={{ height: '100%', width: 'auto' }} defaultSource={Images.ConfettiGif} />
            <Block style={{ position: 'absolute', width: '100%', height: '100%', backgroundColor: materialTheme.COLORS.CARD_BACKGROUND_SPECIAL }} />
          </Block>
        }TODO: Add special confetti */}
        {this.parseIcon(this.props.type)}
        <div className="d-flex flex-column w-100">
          {Array.isArray(this.props.title) ? (
            <div style={{ display: "flex", flexDirection: "row" }}>
              {this.props.title.map((item, index) => (
                <div key={index} style={{ flex: this.props.title.length }}>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "0.75rem",
                      color: this.props.deletedStudent
                        ? Colors.PLACEHOLDERS
                        : Colors.TEXT,
                      textTransform: "capitalize",
                    }}
                  >
                    {item}
                  </span>
                </div>
              ))}
            </div>
          ) : (
            <span
              style={{
                fontWeight: "bold",
                fontSize: "0.875rem",
                color: this.props.deletedStudent
                  ? Colors.PLACEHOLDERS
                  : Colors.TEXT,
                textTransform: "capitalize",
              }}
              size={14}
            >
              {this.props.title}
            </span>
          )}
          {/* <span
            style={{
              fontWeight: "bold",
              fontSize: "0.75rem",
              color: this.props.deletedStudent
                ? Colors.PLACEHOLDERS
                : Colors.TEXT,
              textTransform: "capitalize",
            }}
          >
            {this.props.title}
          </span> */}
          {Array.isArray(this.props.subtitle) ? (
            <div style={{ display: "flex", flexDirection: "row" }}>
              {this.props.subtitle.map((item, index) => (
                <div style={{ flex: this.props.subtitle.length }}>
                  <span
                    key={index}
                    style={{
                      fontSize: "0.75rem",
                      color: Colors.TEXT,
                    }}
                  >
                    {item}
                  </span>
                </div>
              ))}
            </div>
          ) : (
            <span
              style={{
                fontSize: "0.75rem",
                color: this.props.deletedStudent
                  ? Colors.PLACEHOLDERS
                  : Colors.TEXT,
                whiteSpace: "pre-line",
              }}
              size={14}
            >
              {this.props.subtitle}
            </span>
          )}
        </div>
        {this.props.doneMark !== undefined && this.getMark(this.props.doneMark)}
        {this.props.plusIcon && this.parseIcon("plus")}
        {/* {this.props.track !== undefined && this.getTrackIndicator(this.props.track)} */}
        {/* {this.props.removable !== undefined && this.getRemovableBtn()} */}
      </>
    )
  }

  render() {
    let styles = {
      borderWidth: 0.5,
      borderColor: Colors.SEPARATOR,
      boxShadow:
        "0px -6px 23px rgba(52, 52, 52, 0.04), 0px 5px 20px rgba(52, 52, 52, 0.08)",
      elevation: 2,
      height: 65,
      minWidth: 210,
      marginBottom: 25,
      paddingRight: 12,
      borderRadius: 8,
      display: "flex",
      flexDirection: "row",
      justifiContent: "center",
      alignItems: "center",
      backgroundColor: this.props.deletedStudent
        ? Colors.DARKER_BACKGROUND
        : "#fff",
      cursor: this.props.cardPress ? "pointer" : "default",
    }
    if (this.props.style) styles = { ...styles, ...this.props.style }
    return (
      <div
        style={styles}
        onClick={() => {
          if (this.props.cardPress) {
            PlayAudio("Drop")
            this.props.cardPress()
          }
        }}
      >
        {this.renderCard()}
      </div>
    )
  }
}
