import React from "react";
import { Col } from "reactstrap";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction';
export default class Calendar extends React.Component {
  render() {
    return (
      <Col md={7} sm={12}>
        <div style={{
          position: 'fixed',
          width: '45%',
          marginTop: '-40px',
          padding: 15,
          borderRadius: 15,
          boxShadow: '0px -6px 23px rgba(52, 52, 52, 0.04), 0px 5px 20px rgba(52, 52, 52, 0.08)'
        }}>
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            events={this.props.events}
            eventDisplay='block'
            eventContent={(e) => <div style={{ color: e.color, width: 5, height: 5, borderRadius: 100 }} />}
            eventClick={(arg) => this.props.onDateClick(arg.event.startStr)}
            dateClick={(arg) => this.props.onDateClick(arg.dateStr)}
          />
        </div>
      </Col>
    );
  }
}