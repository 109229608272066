import React, { PureComponent } from "react"
import { MoreVertical } from "react-feather"
import Colors from "../helpers/Colors"
import { PopoverBody, UncontrolledPopover } from "reactstrap"

export default class MoreOptions extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      hoveredOptionId: undefined,
    }
    this.selectorRef = React.createRef(null)
  }

  renderOption(child, i = "onlyChild") {
    return (
      <div
        key={i}
        className="cursor-pointer"
        style={{
          backgroundColor:
            this.state.hoveredOptionId === i ? "#7f75" : "inherit",
        }}
        onMouseEnter={() => this.setState({ hoveredOptionId: i })}
        onMouseLeave={() => this.setState({ hoveredOptionId: undefined })}
        onClick={() => {
          if (this.selectorRef) this.selectorRef.click()
        }}
      >
        {child}
      </div>
    )
  }

  render() {
    return (
      <div ref={(r) => (this.selectorRef = r)}>
        <MoreVertical
          size={14}
          color={Colors.PRIMARY}
          style={{ cursor: "pointer" }}
          id={`MoreOptions-${this.props.id}`}
        />
        <UncontrolledPopover
          placement="bottom"
          target={`MoreOptions-${this.props.id}`}
          trigger="legacy"
        >
          <PopoverBody style={{ padding: 0 }}>
            {Array.isArray(this.props.children)
              ? this.props.children.map((e, i) => this.renderOption(e, i))
              : this.renderOption(this.props.children)}
          </PopoverBody>
        </UncontrolledPopover>
      </div>
    )
  }
}

export function Option({ icon, label, onClick }) {
  return (
    <div
      className="d-flex flex-row align-items-center"
      style={{ padding: "0.5rem 1rem" }}
      onClick={onClick}
    >
      {icon}
      <span>{label}</span>
    </div>
  )
}
