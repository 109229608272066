import React from "react"
import {
  Home,
  Users,
  LogOut,
  BarChart2,
  Book,
  Calendar,
  BookOpen,
  List,
  Bookmark,
} from "react-feather"
import { logout } from "../helpers/AuthHelper"

const navigationConfig = [
  {
    id: "home",
    title: "Home",
    type: "item",
    icon: <Home size={20} />,
    navLink: "/",
  },
  {
    id: "students",
    title: "Students",
    type: "item",
    icon: <Users size={20} />,
    navLink: "/students",
    parentOf: ["/student", "/student-books", "/success"],
  },
  {
    id: "dashboard",
    title: "Engagement",
    type: "item",
    icon: <List size={20} />,
    navLink: "/students-dashboard",
  },
  {
    id: "reports",
    title: "Reports",
    type: "item",
    icon: <BarChart2 size={20} />,
    navLink: "/reports",
  },
  // {
  //   id: "achievements",
  //   title: "Achievements",
  //   type: "item",
  //   icon: <BarChart2 size={20} />,
  //   navLink: "/achievements",
  // },
  {
    id: "classwork",
    title: "Classwork",
    type: "item",
    icon: <Calendar size={20} />,
    navLink: "/classwork",
    parentOf: ["/report"],
  },
  {
    id: "guides",
    title: "Implementation Guides",
    type: "item",
    icon: <Bookmark size={20} />,
    navLink: "/guides",
  },
  // {
  //   id: "assign-books",
  //   title: "Assign a Book",
  //   type: "item",
  //   icon: <BookOpen size={20} />,
  //   navLink: "/assign-books",
  //   parentOf: [
  //     "/assigned-books",
  //     "/assigned-book-report",
  //     "/reading-comprehension-report",
  //   ],
  // },
  {
    id: "books",
    title: "Assign a Book",
    type: "collapse",
    icon: <BookOpen size={20} />,
    children: [
      {
        id: "assign-books",
        title: "Assign",
        type: "item",
        navLink: "/assign-books",
      },
      {
        id: "assigned-books",
        title: "Assigned Books",
        type: "item",
        navLink: "/assigned-books",
        parentOf: ["/assigned-book-report", "/reading-comprehension-report"],
      },
    ],
  },
  {
    id: "vocabulary",
    title: "Vocabulary",
    type: "collapse",
    icon: <Book size={20} />,
    children: [
      {
        id: "vocabulary-units",
        title: "Units",
        type: "item",
        navLink: "/vocabulary-units",
        parentOf: ["vocabulary-assign"],
      },
      {
        id: "vocabulary-assigned",
        title: "Assigned",
        type: "item",
        navLink: "/vocabulary-assigned",
      },
      {
        id: "vocabulary-timeline",
        title: "Timelines",
        type: "item",
        navLink: "/vocabulary-timeline",
      },
    ],
  },
  {
    id: "grammar",
    title: "Grammar",
    type: "collapse",
    icon: <Book size={20} />,
    children: [
      {
        id: "grammar-units",
        title: "Units",
        type: "item",
        navLink: "/grammar-units",
        parentOf: ["grammar-assign"],
      },
      {
        id: "grammar-assigned",
        title: "Assigned",
        type: "item",
        navLink: "/grammar-assigned",
      },
      {
        id: "grammar-timeline",
        title: "Timelines",
        type: "item",
        navLink: "/grammar-timeline",
      },
    ],
  },
  {
    id: "logout",
    title: "Logout",
    type: "item",
    icon: <LogOut size={20} />,
    action: () => {
      // eslint-disable-next-line
      if (confirm("Are you sure you want to log out?")) {
        logout()
        return true
      }
      return false
    },
  },
]

export default navigationConfig
