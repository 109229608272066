import React from 'react'
import { Doughnut } from "react-chartjs-2";

export default class Chart extends React.Component {
  options() {
    const options = {
      legend: { display: false }
      //  , tooltips: { callbacks: { label: (label,asd) => { console.log(asd); return `${label.y}%` } } }
    };
    return options;
  }

  render() {
    return (
      <Doughnut data={this.props.data} options={this.options()} width={this.props.size} height={this.props.size} />
    );
  }
}
