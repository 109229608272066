import React from "react"
import { HelpCircle } from "react-feather"
import Colors from "../helpers/Colors"

export function Tooltip({
  className = "",
  children,
  tooltip = undefined,
  bottom = false,
  boxStyle = {},
  ...rest
}) {
  return (
    <div className={`${className} customtooltip`} {...rest}>
      {children}
      {tooltip && (
        <div className={`customtooltip-box${bottom ? " bottom" : ""}`} style={boxStyle}>
          {tooltip}
        </div>
      )}
    </div>
  )
}

export function HelpTooltip({ tooltip = undefined, style = {}, ...rest }) {
  const localStyles = {
    //   margin: 2,
    //   width: "1rem",
    //   height: "1rem",
    //   borderRadius: 100,
    //   border: "1px #555B solid",
    //   color: "#555B",
    //   textAlign: "center",
    //   fontSize: "0.7rem"
    display: "flex",
    padding: "5px",
  }
  return (
    <Tooltip tooltip={tooltip} style={{ ...localStyles, ...style }} {...rest}>
      <HelpCircle color={Colors.PRIMARY} size={16} />
    </Tooltip>
    // <Tooltip tooltip={tooltip} style={{ ...style, ...localStyles }} {...rest}>
    //   ?
    // </Tooltip>
  )
}
