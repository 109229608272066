import React, { useEffect, useState } from "react"
import Colors from "../helpers/Colors"
import {
  ChevronLeft,
  ChevronRight,
  CornerDownLeft,
  Volume2,
} from "react-feather"
import PlayAudio from "../helpers/PlayAudio"
import { Col, Row, Spinner } from "reactstrap"
import ColoredBar from "./ColoredBar"
import Modal from "./Modal"
import Button from "./Button"

export default function Lesson({ words = [] }) {
  const [speaking, setSpeaking] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const [translationModalVisible, setTranslationModalVisible] = useState(false)
  const [activeFlipSide, setActiveFlipSide] = useState("front")

  useEffect(() => {}, [])

  const playVoice = () => {
    if (!speaking) setSpeaking(true)
  }

  useEffect(() => {
    if (speaking) {
      if (words[activeIndex]?.audioLoaded && words[activeIndex]?.audio) {
        PlayAudio(words[activeIndex]?.audio, 0, () => setSpeaking(false))
      } else {
        setSpeaking(false)
      }
    }
    // eslint-disable-next-line
  }, [speaking])

  const changeActiveIndex = (increment) => {
    setActiveFlipSide("front")
    let newIndex = activeIndex + increment
    if (newIndex < 0) {
      newIndex = 0
    } else if (newIndex > words.length - 1) {
      newIndex = words.length - 1
    }
    setActiveIndex(newIndex)
  }

  const renderSentence = () => {
    if (!words[activeIndex]?.sentence) return null
    let pieceOfSentence = words[activeIndex].sentence.split(
        words[activeIndex].word
      ),
      actualWord = words[activeIndex].word
    if (pieceOfSentence.length === 1) {
      pieceOfSentence = words[activeIndex].sentence.split(
        words[activeIndex].word.toLowerCase()
      )
      actualWord = words[activeIndex].word.toLowerCase()
    }
    return (
      <p
        style={{
          textAlign: "center",
          color: "#fff",
          fontSize: "1.75rem",
          fontFamily: "Poppins",
          lineHeight: "3rem",
          textWrap: "balance",
        }}
      >
        {/* {words[activeIndex]?.sentence} */}
        {pieceOfSentence[0]}
        <span style={{ color: Colors.PURPLE }}>{actualWord}</span>
        {pieceOfSentence[1]}
      </p>
    )
  }

  return (
    <>
      <Modal
        title={"Translation"}
        visible={translationModalVisible}
        closeModal={() => setTranslationModalVisible(false)}
      >
        <p>{words[activeIndex]?.translation}</p>
        <Button
          text={"Got it!"}
          onClick={() => setTranslationModalVisible(false)}
        />
      </Modal>
      <Row className="my-2 mx-3 justify-content-around align-items-center">
        <Col xs={2}></Col>
        <Col xs={8}>
          <ColoredBar
            style={{ height: 10, width: "100%" }}
            bars={[
              {
                color: Colors.PURPLE,
                size: activeIndex / words.length,
              },
            ]}
          />
        </Col>
        <Col xs={2}>
          <span style={{ fontSize: "1.2rem", color: "white" }}>
            {activeIndex + 1} / {words.length}
          </span>
        </Col>
      </Row>
      <div
        className="d-flex h-100 p-2 position-relative"
        style={{ overflowY: "auto" }}
      >
        <div className="w-100 d-flex flex-column justify-content-between align-items-center">
          <div
            // className="p-1"
            style={{
              borderRadius: 15,
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#fff",
              position: "relative",
              width: "60%",
              height: "40%",
            }}
          >
            {words[activeIndex]?.imgLoaded ? (
              <img
                src={words[activeIndex]?.img}
                alt=""
                style={{
                  objectFit: "contain",
                  minWidth: "100%",
                  minHeight: "100%",
                  maxWidth: "60%",
                  maxHeight: "40%",
                }}
              />
            ) : (
              <Spinner color="primary" style={{ height: 50, width: 50 }} />
            )}
            <div
              style={{ position: "absolute", zIndex: 10, bottom: 5, right: 5 }}
              onClick={() => playVoice()}
            >
              <div
                className="scalableHover"
                style={{
                  backgroundColor: `${Colors.PURPLE}${!speaking ? "FF" : "88"}`,
                  borderRadius: 100,
                  boxShadow: !speaking
                    ? "1px 1px 11px 0px rgba(0,0,0,0.15)"
                    : undefined,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 40,
                  height: 40,
                  cursor: "pointer",
                  zIndex: 10,
                }}
              >
                <Volume2 color={speaking ? Colors.RED : "#fff"} size={25} />
              </div>
            </div>
          </div>
          <div
            className="my-2 p-1 d-flex flex-row justify-content-between align-items-center"
            style={{ width: "90%" }}
          >
            <div
              className={`${activeIndex !== 0 ? "scalableHover" : ""}`}
              style={{
                backgroundColor: activeIndex !== 0 ? "#fff" : "#bbb",
                borderRadius: 100,
                boxShadow:
                  activeIndex !== 0
                    ? "1px 1px 11px 0px rgba(0,0,0,0.15)"
                    : undefined,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 60,
                height: 60,
                cursor: activeIndex !== 0 ? "pointer" : "not-allowed",
                zIndex: 10,
              }}
              onClick={() => changeActiveIndex(-1)}
            >
              <ChevronLeft color={Colors.PURPLE} size={40} />
            </div>
            <div
              className="d-flex flex-row justify-content-center align-items-center"
              style={{ flex: 1 }}
            >
              <div
                style={{
                  padding: "1rem 2.5rem",
                  backgroundColor: Colors.PURPLE,
                  borderRadius: 15,
                  boxShadow: "-3px 3px 0px 0px rgba(61,70,178,1)",
                }}
              >
                <h2
                  style={{
                    color: "#fff",
                    textAlign: "center",
                    margin: 0,
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    fontFamily: "Poppins",
                  }}
                >
                  {words[activeIndex]?.word}
                </h2>
              </div>
            </div>
            <div
              className={`${
                activeIndex !== words.length - 1 ? "scalableHover" : ""
              }`}
              style={{
                backgroundColor:
                  activeIndex !== words.length - 1 ? "#fff" : "#bbb",
                borderRadius: 100,
                boxShadow:
                  activeIndex !== words.length - 1
                    ? "1px 1px 11px 0px rgba(0,0,0,0.15)"
                    : undefined,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 60,
                height: 60,
                cursor:
                  activeIndex !== words.length - 1 ? "pointer" : "not-allowed",
                zIndex: 10,
              }}
              onClick={() => {
                if (activeIndex !== words.length - 1) changeActiveIndex(1)
              }}
            >
              <ChevronRight color={Colors.PURPLE} size={40} />
            </div>
          </div>
          {activeFlipSide === "front" ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                display: "flex",
                flex: 1,
                backgroundColor: Colors.ORANGE,
                position: "relative",
                height: "100%",
                width: "90%",
              }}
            >
              <p
                style={{
                  textAlign: "center",
                  color: "#fff",
                  fontSize: "1.75rem",
                  fontFamily: "Poppins",
                  lineHeight: "3rem",
                  textWrap: "balance",
                }}
              >
                {words[activeIndex]?.description}
              </p>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                backgroundColor: Colors.ORANGE,
                position: "relative",
                height: "100%",
                width: "90%",
              }}
            >
              <h3
                className="mb-1"
                style={{
                  color: "#fff",
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontWeight: "normal",
                  textDecoration: "underline",
                }}
              >
                In a sentence:
              </h3>
              {renderSentence()}
            </div>
          )}
          <div
            className="d-flex justify-content-center align-items-center scalableHover"
            style={{
              position: "absolute",
              zIndex: 10,
              bottom: 15,
              right: 15,
              cursor: "pointer",
              borderRadius: 100,
              backgroundColor: "#fff",
              width: 30,
              height: 30,
            }}
            onClick={() =>
              setActiveFlipSide(activeFlipSide === "front" ? "back" : "front")
            }
          >
            {activeFlipSide === "front" ? (
              <span style={{ color: Colors.PURPLE, fontSize: "1.5rem" }}>
                ?
              </span>
            ) : (
              <CornerDownLeft color={Colors.PURPLE} size={21} />
            )}
          </div>
        </div>
      </div>
    </>
  )
}
