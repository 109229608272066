import React from "react"
import { Search, X } from "react-feather"
import { Input, InputGroup, InputGroupAddon } from "reactstrap"
import Colors from "../helpers/Colors"

export default class BookSearchInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lastSearched: "",
      text: "",
    }
  }

  onChangeLocal = (text) => {
    this.setState({ text: text }, () => {
      if (this.props.onChange) {
        this.props.onChange(text)
      }
    })
  }

  search = (text = this.state.text) => {
    if (this.props.btnPress) {
      this.props.btnPress(text)
      this.setState({ lastSearched: text, text })
    }
  }

  handleSearch = () => {
    this.state.lastSearched !== this.state.text
      ? this.search()
      : this.search("")
  }

  render() {
    return (
      <InputGroup style={{ width: "50%" }}>
        <Input
          placeholder="Search books"
          style={{
            fontSize: "1rem",
            borderRadius: "100px 0px 0px 100px",
            width: "50%",
            height: 50,
            backgroundColor: "#fff",
            color: Colors.GREEN,
            borderColor: Colors.GREEN,
          }}
          value={this.state.text}
          onChange={(e) => this.onChangeLocal(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              this.handleSearch()
            }
          }}
        />
        <InputGroupAddon
          addonType="append"
          className="d-flex align-items-center px-1 overflow-hidden"
          style={{
            borderRadius: "0px 100px 100px 0px",
            cursor: this.state.text && "pointer",
            backgroundColor: Colors.ICON_BACKGROUND_GREEN,
            border: `1px solid ${Colors.GREEN}`,
          }}
          onClick={this.handleSearch}
        >
          {this.state.lastSearched === this.state.text && this.state.text ? (
            <X size={16} color={Colors.GREEN} />
          ) : (
            <Search size={16} color={Colors.GREEN} />
          )}
        </InputGroupAddon>
      </InputGroup>
    )
  }
}
