import Provider from "./Provider"
import { errorHandler } from "./errorHandler"
import {
  studentsMapper,
  studentsListMapper,
  studentMiscues,
  addedRemovedStudentsMapper,
  googleClassroomStudentsMapper,
  simpleStudentsMapper,
  secondDashboardDataMapper,
  getFromToReadBooksMapper,
  getFromToReadingTimeMapper,
  getFromToAssessmentsMapper,
  getFromToCoachingSessionsMapper,
  getFromToReadingComprehensionMapper,
  getFromToConversationsCompletedMapper,
} from "../mappers/studentsMapper"

export const getFollowUp = (classId) => {
  //TODO: proximamente se reemplaza por el de abajo
  return new Promise((resolve, reject) => {
    Provider.get(`/students/follow-up/${classId}`)
      .then((response) => {
        resolve(studentsMapper(response))
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getSimpleStudents = (class_id, is_followup = false) => {
  return new Promise((resolve, reject) => {
    const DTO = { class_id, is_followup }
    Provider.post(`/students-simple`, DTO)
      .then((response) => {
        resolve(simpleStudentsMapper(response))
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getStudents = (classId) => {
  //TODO: proximamente se reemplaza por el de arriba
  return new Promise((resolve, reject) => {
    Provider.get(`/students/${classId}`)
      .then((response) => {
        resolve(studentsListMapper(response))
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getMiscues = (studentId) => {
  return new Promise((resolve, reject) => {
    Provider.get(`/students/most-freq-miscues/${studentId}`)
      .then((response) => {
        resolve(studentMiscues(response))
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getFilteredStudents = (
  class_id,
  min_lexile,
  max_lexile,
  assessment,
  grade_level,
  sort_by = "LEXILE"
) => {
  return new Promise((resolve, reject) => {
    let last_assessment
    switch (assessment) {
      case "days":
        last_assessment = 3
        break
      case "week":
        last_assessment = 7
        break
      case "weeks":
        last_assessment = -1
        break
      default:
        last_assessment = undefined
    }
    const DTO = {
      class_id,
      min_lexile,
      max_lexile,
      last_assessment,
      grade_level,
      sort_by,
    }
    Provider.post("/students/students-filters", DTO)
      .then((response) => {
        resolve(studentsListMapper(response))
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const addRemoveStudents = (classId, add_students, remove_students) => {
  return new Promise((resolve, reject) => {
    const DTO = { add_students, remove_students }
    Provider.post(`/students/add-remove/${classId}`, DTO)
      .then((response) => {
        resolve(addedRemovedStudentsMapper(response))
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getStudentDemoToken = (classId) => {
  return new Promise((resolve, reject) => {
    Provider.get(`/student/demo/${classId}`)
      .then((response) => {
        resolve(response?.url_token)
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const authGoogleClassroom = (class_id) => {
  const DTO = { class_id }
  return new Promise((resolve, reject) => {
    Provider.post(`/auth_googleclassroom`, DTO)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getClassroomStudents = (classId) => {
  return new Promise((resolve, reject) => {
    Provider.get(`/classroomtemp/${classId}`)
      .then((response) => {
        resolve(googleClassroomStudentsMapper(response))
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getStudentsNeedAssessment = (classId) => {
  return new Promise((resolve, reject) => {
    Provider.get(`students/need-assessment/${classId}`)
      .then((response) => {
        resolve(simpleStudentsMapper(response))
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const postStudentsNeedAssessmentNotify = (students_id = []) => {
  const DTO = { students_id }
  return new Promise((resolve, reject) => {
    Provider.post(`students/need-assessment-notif`, DTO)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getGradeLevel = (studentId) => {
  return new Promise((resolve, reject) => {
    Provider.get(`student/grade-level/${studentId}`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getCountReadbooks = (studentId) => {
  return new Promise((resolve, reject) => {
    Provider.get(`student/count-readbooks/${studentId}`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getLastAssessInfo = (studentId) => {
  return new Promise((resolve, reject) => {
    Provider.get(`student/last-assess-info/${studentId}`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getLastBooksInfo = (studentId) => {
  return new Promise((resolve, reject) => {
    Provider.get(`student/last-books-info/${studentId}`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

// export const getWcpmInfo = (studentId) => {
//   return new Promise((resolve, reject) => {
//     Provider.get(`student/wcpm-info/${studentId}`)
//       .then((response) => {
//         resolve(response)
//       })
//       .catch((error) => {
//         !errorHandler(error) && reject(error)
//       })
//   })
// }

export const getLexileInfo = (studentId) => {
  return new Promise((resolve, reject) => {
    Provider.get(`student/lexile-info/${studentId}`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getTotalPractice = (studentId) => {
  return new Promise((resolve, reject) => {
    Provider.get(`practice/student/count-completed/${studentId}`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getStruggle = (studentId) => {
  return new Promise((resolve, reject) => {
    Provider.get(`practice/student/struggle/${studentId}`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getCategoriesToPractice = (studentId, group_token = undefined) => {
  return new Promise((resolve, reject) => {
    Provider.get(
      `practice/student/categories-to-practice/${studentId}${
        group_token ? `/${group_token}` : ""
      }`
    )
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getCategoriesToRevise = (studentId, group_token = undefined) => {
  return new Promise((resolve, reject) => {
    Provider.get(
      `practice/student/categories-to-revise/${studentId}${
        group_token ? `/${group_token}` : ""
      }`
    )
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getCategories = () => {
  return new Promise((resolve, reject) => {
    Provider.get(`practice/all-categories`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getCategoriesAssessed = (student_id, group_token) => {
  return new Promise((resolve, reject) => {
    Provider.get(
      `practice/student/categories-assessed/${student_id}/${group_token}`
    )
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getTroubleWords = (student_id, group_token) => {
  return new Promise((resolve, reject) => {
    Provider.get(`practice/student/truble-words/${student_id}/${group_token}`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getPatternsAssessed = (student_id, group_token) => {
  return new Promise((resolve, reject) => {
    Provider.get(
      `practice/student/patterns-assessed/${student_id}/${group_token}`
    )
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getStudentIsLeveled = (student_id) => {
  return new Promise((resolve, reject) => {
    Provider.get(`practice/student/is-leveled/${student_id}`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getPdfStudent = (student_id, lang) => {
  return new Promise((resolve, reject) => {
    Provider.get(`pdf-student-urltoken/${student_id}/${lang}`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getPdfStudentDiploma = (student_id) => {
  return new Promise((resolve, reject) => {
    Provider.get(`pdf-student-diploma/${student_id}`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getPdfClass = (class_id, lang) => {
  return new Promise((resolve, reject) => {
    Provider.get(`pdf-class-urltoken/${class_id}/${lang}`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getSecondDashboardData = (student_id, time_type) => {
  return new Promise((resolve, reject) => {
    Provider.get(`students-second-dashboard/${student_id}/${time_type}`)
      .then((response) => {
        resolve(secondDashboardDataMapper(response))
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getFromToReadBooks = (student_id, from_date, to_date) => {
  return new Promise((resolve, reject) => {
    Provider.get(
      `students-second-dashboard/from-to-books-read/${student_id}/${from_date}/${to_date}`
    )
      .then((response) => {
        resolve(getFromToReadBooksMapper(response))
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getFromToReadingTime = (student_id, from_date, to_date) => {
  return new Promise((resolve, reject) => {
    Provider.get(
      `students-second-dashboard/from-to-reading-time/${student_id}/${from_date}/${to_date}`
    )
      .then((response) => {
        resolve(getFromToReadingTimeMapper(response))
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getFromToAssessments = (student_id, from_date, to_date) => {
  return new Promise((resolve, reject) => {
    Provider.get(
      `students-second-dashboard/from-to-completed-missions/${student_id}/${from_date}/${to_date}`
    )
      .then((response) => {
        resolve(getFromToAssessmentsMapper(response))
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getFromToCoachingSessions = (student_id, from_date, to_date) => {
  return new Promise((resolve, reject) => {
    Provider.get(
      `students-second-dashboard/from-to-completed-practice-sessions/${student_id}/${from_date}/${to_date}`
    )
      .then((response) => {
        resolve(getFromToCoachingSessionsMapper(response))
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getFromToReadingComprehension = (
  student_id,
  from_date,
  to_date
) => {
  return new Promise((resolve, reject) => {
    Provider.get(
      `students-second-dashboard/from-to-tot-reading-comprehension/${student_id}/${from_date}/${to_date}`
    )
      .then((response) => {
        resolve(getFromToReadingComprehensionMapper(response))
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getFromToConversations = (student_id, from_date, to_date) => {
  return new Promise((resolve, reject) => {
    Provider.get(
      `students-second-dashboard/from-to-completed-conversations/${student_id}/${from_date}/${to_date}`
    )
      .then((response) => {
        resolve(getFromToConversationsCompletedMapper(response))
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getFromToVocabularySessions = (student_id, from_date, to_date) => {
  return new Promise((resolve, reject) => {
    Provider.get(
      `students-second-dashboard/from-to-vocabulary-sessions/${student_id}/${from_date}/${to_date}`
    )
      .then((response) => {
        resolve(getFromToAssessmentsMapper(response))
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getFromToVocabularyAvg = (student_id, from_date, to_date) => {
  return new Promise((resolve, reject) => {
    Provider.get(
      `students-second-dashboard/from-to-vocabulary-avg/${student_id}/${from_date}/${to_date}`
    )
      .then((response) => {
        resolve(getFromToAssessmentsMapper(response))
      })
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const putStudentLang = (student_id, lang) => {
  return new Promise((resolve, reject) => {
    Provider.post("student/lang/put-lang", { student_id, lang })
      .then((response) => resolve(response))
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}
