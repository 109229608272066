import React from "react";
import Colors from "../helpers/Colors";
import PlayAudio from "../helpers/PlayAudio";

export default class FloatingOptions extends React.Component {
  onClick(callback) {
    if (callback) {
      PlayAudio('Drop');
      callback();
    }
  }
  render() {
    return (
      <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 4,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        backgroundColor: `${Colors.BLACK}55`,
      }}>
        <div className='d-flex flex-column align-items-end m-3 mb-5'>
          {this.props.array && this.props.array.map((e, i) =>
            <div key={i} className='bg-white p-1 mb-2' style={{ borderRadius: 100, cursor: e.onClick && 'pointer' }} onClick={() => this.onClick(e.onClick)}>
              {this.props.element(e, i)}
            </div>
          )}
        </div>
      </div>
    );
  }
}