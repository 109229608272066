import React from "react";
import Colors from "../helpers/Colors";
import { ArrowLeft } from "react-feather";
import PlayAudio from "../helpers/PlayAudio";

export default class SideModal extends React.Component {
  close = () => {
    if (this.props.closeModal) {
      PlayAudio('Drop');
      this.props.closeModal();
    }
  }
  render() {
    return [
      <div key='background'
        style={{
          position: 'fixed',
          top: 0,
          right: '-65vw',
          zIndex: 5,
          width: '65vw',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.2)',
          transform: this.props.visible && `translate(-100vw)`,
          transition: 'all 0.5s ease-out',
          justifyContent: 'flex-end'
        }}
        onClick={this.close}
      />,
      <div key='modal'
        style={{
          position: 'fixed',
          top: 0,
          right: '-35vw',
          zIndex: 5,
          width: '35vw',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.2)',
          transform: this.props.visible && `translate(-35vw)`,
          transition: 'all 0.5s ease-out',
        }}
      >
        <div
          style={{
            position: 'absolute',
            width: '35vw',
            height: '100vh',
            overflowY: 'auto',
            borderTopLeftRadius: 15,
            backgroundColor: 'white',
            zIndex: 10
          }}
        >
          {!this.props.filter ?
            <div className='w-100 d-flex flex-column justify-content-around p-2'
              style={{ height: 100, backgroundColor: Colors.GREEN, borderTopLeftRadius: 15 }}
            >
              <img src={require('../assets/img/confetti/confetti.png')} alt='Confetti' style={{ position: 'absolute', right: 0, top: -10, height: 90 }} />
              <div className='d-flex flex-row justify-content-between align-items-center'>
                <ArrowLeft size={24} color='#fff' style={{ cursor: 'pointer' }} onClick={this.close} />
                {this.props.rightIcon &&
                  <div className='d-flex flex-row align-items-center' style={{ cursor: 'pointer', zIndex: 2 }} onClick={() => { PlayAudio('Drop'); this.props.rightIconPress() }}>
                    {this.props.rightIcon}
                    <span style={{ fontWeight: 'bold', fontSize: '1rem', color: '#fff', marginLeft: 5 }}>{this.props.rightIconText}</span>
                  </div>
                }
              </div>
              {this.props.title && <span style={{ fontWeight: 'bold', fontSize: '1.375rem', color: '#fff' }}>{this.props.title}</span>}
            </div>
            :
            <div className='d-flex flex-row justify-content-between p-2'>
              <span style={{ fontWeight: 'bold', fontSize: '1.375rem', color: Colors.TEXT }}>Filters</span>
              {this.props.clearAllFilters &&
                <span style={{ fontWeight: 'bold', fontSize: '1rem', color: Colors.GREEN, cursor: 'pointer' }} onClick={() => { PlayAudio('Drop'); this.props.clearAllFilters(); }}>
                  Clear all
                </span>
              }
            </div>
          }
          <div className='p-2' style={{ marginTop: -15, borderTopLeftRadius: 15, backgroundColor: 'white', overflowY: 'auto' }}>
            {this.props.children}
          </div>
        </div>
      </div>
    ]
  }
}