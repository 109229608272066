export function gradeMapper(grade) {
  return {
    id: grade._id,
    name: grade.name,
    order: grade.order,
    min_lexile: grade.min_lexile,
    max_lexile: grade.max_lexile,
    label: grade.name,
    value: grade._id,
  }
}

export function gradesMapper(grades = []) {
  const environmentName = process.env.NODE_ENV
  return grades
    .filter((grade) =>
      environmentName === "development" ? true : grade.order <= 18
    )
    .map((grade) => gradeMapper(grade))
}
