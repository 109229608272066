export function simpleStudentsMapper(students) {
  return students.map((student) => ({
    id: student.id || student._id,
    name:
      student.name ||
      student.firstname + (student.lastname ? " " + student.lastname : ""),
    firstname: student.firstname,
    url_token: student.url_token,
    grade: student.grade_level?.name,
    lang: student.lang ?? "eng",
  }))
}

export function studentMapper(student) {
  const parsedStudent = {
    id: student._id,
    name: student.firstname + (student.lastname ? " " + student.lastname : ""),
    firstname: student.firstname,
    lastname: student.lastname,
    // wcpm: student.wcpm
    //   ? parseFloat(student.wcpm?.$numberDecimal).toFixed(0)
    //   : undefined,
    lexile: student.lexile
      ? parseFloat(student.lexile?.$numberDecimal).toFixed(0)
      : undefined,
    reading: student.reading,
    grade: student.grade_level?.name,
    // track: student.grade_track,
    starred: student.starred,
    exam: {
      completed: student.exam?.completed,
      approved: student.exam?.approved,
      checked: student.exam?.checked,
    },
    practice: {
      done: student.practice?.obj.length > 0,
    },
    demo: student.demo,
    url_token: student.url_token,
    lastAssessment: {
      lexile: {
        value:
          student.assessment_info?.last_assessment_lexile || student.lexile
            ? parseFloat(student.lexile?.$numberDecimal).toFixed(0)
            : undefined,
        vsLast: student.assessment_info?.vs_last_assessment_lexile || undefined,
        spot: student.lexile_info?.lexile_spot || undefined,
        goal: student.lexile_info?.lexile_goal || undefined,
      },
      // wcpm: {
      //   value: student.wcpm_info?.last_wcpm
      //     ? parseFloat(student.wcpm_info?.last_wcpm).toFixed(0)
      //     : undefined,
      //   vsLast: student.wcpm_info?.vs_last_wcpm
      //     ? parseFloat(student.wcpm_info?.vs_last_wcpm).toFixed(0)
      //     : undefined,
      //   spot: student.wcpm_info?.wcpm_spot || undefined,
      //   goal: student.wcpm_info?.wcpm_goal || undefined,
      // },
    },
    readBooks: {
      value: student.read_books,
      // vsLast: student.books_info?.vs_last_books || undefined,
      // spot: student.books_info?.books_spot || undefined,
    },
    lang: student.lang ?? "eng",
  }
  return parsedStudent
}

export function studentsMapper(students) {
  const studentsArray = []
  students.forEach((student) => {
    studentsArray.push(studentMapper(student))
  })

  return studentsArray
}

export function studentsListMapper(students) {
  const studentList = []
  students.forEach((student) => {
    studentList.push(studentMapper(student))
  })
  return studentList
}

export function addedRemovedStudentsMapper(students) {
  return students
}

export function studentMiscues(miscues) {
  let parsedMiscues = {},
    total = 0
  miscues.forEach((e) => {
    parsedMiscues[e.miscue] = e.qty
    total += e.qty
  })
  return { total, miscues: parsedMiscues }
}

export function googleClassroomStudentsMapper(students) {
  return students
    ? students.map((s) => {
        return { name: s.name, fromGC: true }
      })
    : null
}

export function secondDashboardDataMapper(studentData) {
  return {
    ReadBooks: studentData.read_books,
    ReadingTime: studentData.reading_time,
    Assessments: studentData.assessments,
    CoachingSessions: studentData.coaching_sessions,
    // ReadingComprehension: studentData.reading_comprehension
    //   ? `${studentData.reading_comprehension}%`
    //   : studentData.reading_comprehension,
    ReadingComprehension: studentData?.reading_comprehension
      ? {
          total:
            studentData?.reading_comprehension?.avg_rc_tot !== null
              ? `${studentData?.reading_comprehension?.avg_rc_tot}%`
              : "-",
          assignedReading:
            studentData?.reading_comprehension?.avg_assigned_rc_reading !== null
              ? `${studentData?.reading_comprehension?.avg_assigned_rc_reading}%`
              : "-",
          reading:
            studentData?.reading_comprehension?.avg_free_rc_reading !== null
              ? `${studentData?.reading_comprehension?.avg_free_rc_reading}%`
              : "-",
          fluency:
            studentData?.reading_comprehension?.avg_rc_exam !== null
              ? `${studentData?.reading_comprehension?.avg_rc_exam}%`
              : "-",
        }
      : null,
    TotalConversationsCompleted: studentData?.total_conversations_completed,
    TotalVocabularySessions: studentData?.total_vocabulary_sessions,
    AvgVocabularyScore: studentData?.avg_vocabulary_score,
  }
}

export function getFromToReadBooksMapper(data) {
  return data
}

export function getFromToReadingTimeMapper(data) {
  return data
}

export function getFromToAssessmentsMapper(data) {
  return data
}

export function getFromToCoachingSessionsMapper(data) {
  return data
}

export function getFromToReadingComprehensionMapper(data) {
  return data
    ? {
        total: `${data.avg_rc_tot !== null ? data.avg_rc_tot : "-"}%`,
        assignedReading: `${
          data.avg_assigned_rc_reading !== null
            ? data.avg_assigned_rc_reading
            : "-"
        }%`,
        reading: `${
          data.avg_free_rc_reading !== null ? data.avg_free_rc_reading : "-"
        }%`,
        fluency: `${data.avg_rc_exam !== null ? data.avg_rc_exam : "-"}%`,
      }
    : null
}

export function getFromToConversationsCompletedMapper(data) {
  return data
}

export function getFromToVocabularySessionsMapper(data) {
  return data
}

export function getFromToAvgVocabularyScoreMapper(data) {
  return data
}
