import React, { useEffect, useMemo, useRef, useState } from "react"
import { logError } from "../../helpers/ErrorHelper"
import { ReadingSystemAPI } from "./ReadingSystemAPI"
import { KeyCode } from "@colibrio/colibrio-reader-framework/colibrio-core-base"
import NavigationView from "./NavigationView"
import AppMediaOverlayControls from "./AppMediaOverlayControls"
import { ReadAloudMenu } from "./ReadAloudMenu"
import axios from "axios"
import { URL_BACKEND } from "../../config"

let appMediaOverlayControl = undefined

export function ColibrioView({
  bookId,
  token,
  componentPrevPage,
  componentNextPage,
  onLoad,
}) {
  const viewRef = useRef(null)
  let [publicationLoaded, setPublicationLoaded] = useState(false)
  let [timelineLength, setTimelineLength] = useState(0)
  let [readingPositionData, setReadingPositionData] = useState(undefined)
  const [book, setBook] = useState(null)

  const readingSystemInstance = useMemo(() => {
    return ReadingSystemAPI.createInstance(
      process.env.REACT_APP_COLIBRIO_LICENSE_API_KEY
    )
  }, [])

  const onPublicationLoaded = async (publication) => {
    if (publication) {
      setTimelineLength(
        readingSystemInstance.contentPositionTimelineLength || 0
      )
      readingSystemInstance.setReadingProgressCallback(setReadingPositionData)
      readingSystemInstance.setKeyUpCallback(onKeyUp)
      // readingSystemInstance.setPointerUpCallback(onPointerUp)
      // readingSystemInstance.setKeyDownCallback(onKeyDown)
      // readingSystemInstance.setSelectionChangeCallback(onSelectionChange)
      // readingSystemInstance.setPointerDownCallback(onPointerDown)
      // readingSystemInstance.setNavigationIntentCallback(onNavigationIntent)
      if (book?.format_type === "epub") {
        const mediaOverlay = await AppMediaOverlayControls.create(
          readingSystemInstance.readerView,
          publication,
          document.getElementById("media-overlay-controls-container")
        ).catch((reason) => {
          logError(reason, 2)
          console.log("ERROR ON APPMEDIAOVELAR")
          return undefined
        })
        appMediaOverlayControl = mediaOverlay
      }
      setPublicationLoaded(true)
      onLoad(readingSystemInstance.contentPositionTimelineLength || 1)
    }
  }

  useEffect(() => {
    if (book) {
      readingSystemInstance
        .loadPublicationByUrl(book?.file_url)
        .then((publication) => {
          window.addEventListener("resize", onViewRefresh)
          window.addEventListener("keyup", onKeyUp)
          onPublicationLoaded(publication)
        })
        .catch((error) => {
          logError(error, 2)
        })
    }
    // eslint-disable-next-line
  }, [book])

  useEffect(() => {
    const provider = axios.create({ baseURL: URL_BACKEND })
    provider.defaults.headers.common["Authorization"] = `Bearer ${token}`
    provider
      .get(`${URL_BACKEND}/app-student/get-book/${bookId}`)
      .then((response) => {
        setBook(response?.data?.data)
      })
      .catch((e) => {
        logError(e)
      })
    if (componentPrevPage !== undefined) {
      componentPrevPage(onNavigatePrevious)
    }
    if (componentNextPage !== undefined) {
      componentNextPage(onNavigateNext)
    }
    return () => {
      if (readingSystemInstance) {
        readingSystemInstance.renderToElement(null)
        readingSystemInstance.readerView.setReaderDocuments([])
        if (appMediaOverlayControl) {
          appMediaOverlayControl.destroy()
        }
      }
      window.removeEventListener("resize", onViewRefresh)
      window.removeEventListener("keyup", onKeyUp)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (viewRef && viewRef.current) {
      readingSystemInstance.renderToElement(viewRef.current)
      // onRendered()
    }
    // eslint-disable-next-line
  }, [publicationLoaded])

  const onNavigateNext = () => {
    readingSystemInstance.next()
  }

  const onNavigatePrevious = () => {
    readingSystemInstance.previous()
  }

  const onGotoTimelinePosition = async (position) => {
    const contentLocation =
      await readingSystemInstance.fetchContentLocationFromContentPositionTimelinePosition(
        position
      )
    if (!contentLocation) {
      console.warn(
        "ReaderApp.onGotoTimelinePosition(), Unable to fetch ContentLocation for timeline position"
      )
      return
    }
    readingSystemInstance.goto(contentLocation.getLocator()).catch(console.warn)
  }

  const onKeyUp = (event) => {
    switch (event.keyCode) {
      case KeyCode.KEY_LEFT:
        onNavigatePrevious()
        break
      case KeyCode.KEY_RIGHT:
        onNavigateNext()
        break
      default:
        break
    }
  }

  // const onSelectionChange = (event) => {
  //   if (event.isRange) {
  //     setTextSelected(true)
  //     activeSelectionLocator = event.contentLocation?.getLocator()
  //   } else {
  //     setTextSelected(false)
  //     activeSelectionLocator = undefined
  //   }
  // }
  // const onNavigationIntent = (event) => {
  //   closeAllOpenDialogs()
  // }
  // const onPointerUp = (event) => {
  //   let locator = event.target?.contentLocation?.getLocator()
  //   if (locator && !readingSystemInstance.isNavigating) {
  //     readingSystemInstance.goto(locator).catch(console.warn)
  //   }
  // }
  // const onPointerDown = (event) => {}
  // const onKeyDown = (event) => {}
  // const onRendered = () => {}
  // const closeAllOpenDialogs = () => {}

  const onViewRefresh = () => {
    readingSystemInstance.refreshReaderView()
  }

  return (
    <div className="d-flex flex-column h-100">
      <NavigationView
        timelineLength={timelineLength}
        timelinePosition={readingPositionData?.timelinePosition}
        onSliderPositionChange={onGotoTimelinePosition}
        onNavigatePrevious={onNavigatePrevious}
        onNavigateNext={onNavigateNext}
        previousBtnEnabled={readingSystemInstance?.canPerformPrevious}
        nextBtnEnabled={readingSystemInstance?.canPerformNext}
      />
      <div
        id="reader-view"
        style={{
          flex: 1,
          minHeight: "30vh",
          height: "100%",
          maxHeight: window.innerWidth,
          width: "100%",
          position: "relative",
        }}
        ref={viewRef}
      />
      {book?.format_type === "epub" ? (
        <div
          style={{
            width: "fit-content",
            margin: "0 auto",
            paddingTop: "0.5rem",
          }}
        >
          <ReadAloudMenu />
        </div>
      ) : null}
    </div>
  )
}
