import React from "react";
import { Bar } from "react-chartjs-2";

export default class Chart extends React.Component {
  parseLabelData() {}

  options() {
    const maxValue = Math.ceil(
      Math.max.apply(0, this.props.datasetsArray[0].data) * 1.25
    );
    // console.log("maxValue", maxValue);
    const options = {
      legend: { display: false },
      scales: { yAxes: [{ max: maxValue }] },
    };
    if (this.props.type) {
      options.scales = {
        yAxes: [
          {
            ticks: {
              callback: (label) => {
                if (this.props.type === "g") {
                  return this.props?.grades
                    ? this.props.grades.find((grade) => grade.order === label)
                        ?.name
                    : label;
                } else if (this.props.type === "l") {
                  return `${label}L`;
                } else {
                  return label;
                }
              },
              beginAtZero: true,
            },
            max: maxValue,
          },
        ],
      };
      options.tooltips = {
        mode: "label",
        callbacks: {
          title: function (tooltipItem, data) {
            return data.labels[tooltipItem[0].index];
          },

          label: (tooltipItem, data) => {
            let value = data?.datasets[tooltipItem.datasetIndex]?.label || ""
            if (this.props.type === "g") {
              value += this.props?.grades
                ? this.props.grades.find(
                    (grade) => grade.order === tooltipItem.yLabel
                  )?.name
                : tooltipItem.yLabel;
            } else if (this.props.type === "l") {
              value += `${tooltipItem.yLabel}L`;
            } else {
              value += tooltipItem.yLabel;
            }

            return value
          },
        },
      };
    }
    
    return options;
  }

  parseProps = () => {
    const data = this.props.datasetsArray[0];

    const props = {
      labels: this.props.labels,
      datasets: [{ ...{ barPercentage: 0.3 , order: 1 }, ...data }],
    };

    if (this.props.goal) {
      props.datasets.unshift({
        label: "Goal: ", //TODO: Lang
        data: Array(data.data.length).fill(this.props.goal),
        borderColor: "#00b",
        type: "line",
        order: 0,
      });
    }
    
    return props;
  };

  render() {
    return (
      <Bar
        data={this.parseProps()}
        options={this.options()}
        height={this.props.height}
      />
    );
  }
}
