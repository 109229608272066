import Audios from "./Audios"
import { logError } from "./ErrorHelper"

export default (url, playDelay = 0, callback = () => {}) => {
  try {
    const audio = new Audio(Audios[url] || url)
    audio.onended = function () {
      callback()
    }
    return setTimeout(async () => {
      try {
        await audio.play()
      } catch (e) {
        logError(e, 2)
        callback()
      }
    }, playDelay)
  } catch (error) {
    logError(error, 2)
    callback()
  }
}
