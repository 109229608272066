import React from "react";
import { Col, Spinner } from "reactstrap";

export default class SecondColumn extends React.Component {
  render() {
    return (
      <Col md={4} sm={12}>
        <div style={{
          borderTopLeftRadius: 15,
          marginRight: -30,
          marginTop: -30,
          marginBottom: -14,
          padding: 30,
          boxShadow: '0px -6px 23px rgba(52, 52, 52, 0.04), 0px 5px 20px rgba(52, 52, 52, 0.08)',
          height: 'calc(100% + 45px)'
        }}>
          {this.props.loading ?
            <div className='d-flex justify-content-center align-items-center h-100'>
              <Spinner color="primary" style={{ height: 100, width: 100, alignSelf: 'center' }} />
            </div>
            :
            this.props.children
          }
        </div>
      </Col>
    );
  }
}