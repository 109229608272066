import Provider from "./Provider"
import { errorHandler } from "./errorHandler"
import { classesMapper, classMapper, rolesMapper } from "../mappers/classMapper"
import { simpleStudentsMapper } from "../mappers/studentsMapper"

export const getClasses = () => {
  return new Promise((resolve, reject) => {
    Provider.get("users/user-classes")
      .then((response) => resolve(classesMapper(response)))
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getRoles = () => {
  return new Promise((resolve, reject) => {
    Provider.get("secondary-roles")
      .then((response) => resolve(rolesMapper(response)))
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const inviteUser = (class_id, email, role_id) => {
  const DTO = { class_id, email, role_id }
  return new Promise((resolve, reject) => {
    Provider.post("class/invite-user", DTO)
      .then((response) => resolve(response))
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const closeClass = (email_new_teacher, email_admin, class_id) => {
  const DTO = { email_new_teacher, email_admin, class_id }
  return new Promise((resolve, reject) => {
    Provider.post("class/close-class", DTO)
      .then((response) => resolve(classMapper(response)))
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const getNeedLvling = (classId) => {
  return new Promise((resolve, reject) => {
    Provider.get(`practice/class/need-leveling/${classId}`)
      .then((response) => resolve(simpleStudentsMapper(response)))
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}

export const putClassLang = (class_id, lang) => {
  return new Promise((resolve, reject) => {
    Provider.post("class/put-lang/", { class_id, lang })
      .then((response) => resolve(response))
      .catch((error) => {
        !errorHandler(error) && reject(error)
      })
  })
}
