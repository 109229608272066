import React from "react"
import RBAccordion from "react-bootstrap/Accordion"
import { useAccordionButton } from "react-bootstrap/AccordionButton"

function CustomToggle({ children, eventKey, onClick = () => {} }) {
  const decoratedOnClick = useAccordionButton(eventKey, onClick)

  return <div onClick={decoratedOnClick}>{children}</div>
}

export function Accordion({ elements = [] }) {
  return (
    <RBAccordion>
      {elements.map((element, i) => (
        <div key={i}>
          <CustomToggle eventKey={i}>
            {element.toggle(
              <RBAccordion.Collapse eventKey={i}>
                {element.collapsable}
              </RBAccordion.Collapse>
            )}
          </CustomToggle>
        </div>
      ))}
    </RBAccordion>
  )
}
