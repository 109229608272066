import React from 'react';
import { Card } from 'reactstrap';
import Colors from '../helpers/Colors';

export default class BookCard extends React.Component {
  render() {
    return (
      <Card className='d-flex flex-row mt-2 p-1' style={this.props.style}>
        <img src={this.props.image} alt='Book Cover' style={{ height: 110, width: 80, marginRight: 20, borderRadius: 4 }} />
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <span style={{ fontWeight: 'bold', fontSize: '1rem', color: Colors.TEXT, marginBottom: 10 }}>{this.props.title}</span>
          {this.props.children}
        </div>
      </Card>
    );
  }
}