import { classMapper } from "./classMapper"

export function authMapper(auth) {
  if (auth.token == null) {
    return auth
  }
  return {
    token: auth.token,
    user: {
      id: auth.user._id,
      firstname: auth.user.firstname,
      lastname: auth.user.lastname,
      username: auth.user.username,
      email: auth.user.email,
      password: auth.user.password,
      avatar: auth.user.avatar,
      phone: auth.user.phone,
      gender: auth.user.gender,
      badges: auth.user.badges,
      milestones: auth.user.milestones,
      classes: auth.user?.classes
        ? auth.user.classes.map((c) => classMapper(c))
        : [],
      state: auth.user.state,
      role: {
        id: auth.user.role._id,
        name: auth.user.role?.name,
      },
      school: {
        name: auth.user.school?.name,
        district: {
          name: auth.user.school?.district?.name,
        },
      },
      createdAt:
        auth?.user?.created_at || auth?.user?.first_login || undefined,
    },
  }
}

export function sourcesMapper(sources) {
  return sources.map((source) => ({
    value: source._id,
    label: source.name,
  }))
}
