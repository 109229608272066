const FirstTimeAnimation = {
  src: "/lotties/koalaJump.json",
  style: { width: 400, height: 400 },
};

const Modal = {
  src: "/lotties/koalaModal.json",
  style: { width: 300, height: 300, alignSelf: 'center', position: 'absolute', top: -143 },
};

const ModalTalking = {
  src: "/lotties/koalaModalTalking.json",
  style: { width: 300, height: 300, alignSelf: 'center', position: 'absolute', top: -143 },
};

const Countdown = {
  src: "/lotties/countdown.json",
  style: { width: 400, height: 400 },
};

const ToriGuitar = {
  src: "/lotties/koalaGuitar.json",
  style: { width: 300, height: 300, alignSelf: 'center' },
  loop: true
};

const ToriLogin = {
  src: "/lotties/koalaHiNoTalking.json",
  style: { width: 400, height: 400 },
};

const Coin = {
  src: "/lotties/coin.json",
  style: { width: 40, height: 40 },
  loop: true
};

const ArrowRight = {
  src: "lotties/ArrowRight.json",
  loop: true,
  speed: 1.5,
  style: { width: 55, height: 27.5 },
}

export default {
  FirstTimeAnimation,
  Modal,
  ModalTalking,
  Countdown,
  ToriGuitar,
  ToriLogin,
  Coin,
  ArrowRight
}