import React from "react"

export default class List extends React.Component {
  render() {
    return (
      <div
        className={`d-flex flex-column align-items-center ${
          this.props.className ? this.props.className : ""
        }`}
        style={this.props.style}
      >
        {this.props.array &&
          this.props.array.map((e, i) => (
            <div key={i} style={{ width: "100%", margin: 5 }}>
              {this.props.element(e, i)}
            </div>
          ))}
      </div>
    )
  }
}
